const InstagramIcon = (props) => {
  return (
    <svg width="44.518" height="44.511" viewBox="0 0 44.518 44.511" {...props}>
      <path
        d="M22.258,13.081A11.412,11.412,0,1,0,33.67,24.493,11.393,11.393,0,0,0,22.258,13.081Zm0,18.831a7.419,7.419,0,1,1,7.419-7.419,7.432,7.432,0,0,1-7.419,7.419Zm14.54-19.3a2.662,2.662,0,1,1-2.662-2.662A2.656,2.656,0,0,1,36.8,12.614Zm7.558,2.7c-.169-3.566-.983-6.724-3.6-9.326S35,2.573,31.435,2.394c-3.675-.209-14.689-.209-18.363,0-3.555.169-6.714.983-9.326,3.585S.33,11.74.151,15.306C-.057,18.981-.057,30,.151,33.67c.169,3.566.983,6.724,3.6,9.326s5.76,3.417,9.326,3.6c3.675.209,14.689.209,18.363,0,3.565-.169,6.724-.983,9.326-3.6s3.416-5.761,3.6-9.326c.209-3.675.209-14.68,0-18.354Zm-4.747,22.3a7.511,7.511,0,0,1-4.231,4.231c-2.93,1.162-9.882.894-13.119.894s-10.2.258-13.119-.894a7.511,7.511,0,0,1-4.231-4.231c-1.162-2.93-.894-9.882-.894-13.12s-.258-10.2.894-13.12A7.511,7.511,0,0,1,9.139,7.142c2.93-1.162,9.882-.894,13.119-.894s10.2-.258,13.119.894a7.511,7.511,0,0,1,4.231,4.231c1.162,2.93.894,9.882.894,13.12S40.771,34.693,39.609,37.613Z"
        transform="translate(0.005 -2.238)"
      />
    </svg>
  );
};

export default InstagramIcon;
