import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

class FireBaseUserService {
  constructor() {
    this.db = getFirestore();
    this.countMax = 3000;
  }

  async userExist(ethAddress) {
    const docRef = doc(this.db, "users", ethAddress);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    }
    return false;
  }

  async wlCount() {
    const count = collection(this.db, "users")
    const snapshot = await getDocs(count);
    const c2 = snapshot.size;
    console.log(c2);
  }

  async getUser(ethAddress) {
    const docRef = doc(this.db, "users", ethAddress);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async isCountMax() {
    const docRef = doc(this.db, "total", "user_count");
    const docSnap = await getDoc(docRef);
    return docSnap.data().total >= 3000;
  }
  
  async updateCount() {
    debugger;
    const docRef = doc(this.db, "total", "user_count");
    const docSnap = await getDoc(docRef);
    const docData = {
      total: docSnap.data().total + 1
    }
    let res = await setDoc(doc(this.db, "total", "user_count"), docData)
      .then((res) => true)
      .catch((err) => false);
    return res;
  }
  async updateInviter(inviterAddress, inviter, inviteeAddress) {
    inviter.invites.push(inviteeAddress)
    const docData = {
      count: inviter.count - 1,
      invites: inviter.invites
    }
    debugger;
    let res = await setDoc(doc(this.db, "users", inviterAddress), docData)
      .then((res) => true)
      .catch((err) => false);
    return res;
  }

  async addUser(address) {
    debugger;
    let res = await setDoc(doc(this.db, "users", address), {
      count: 3,
      invites: [],
    })
      .then((res) => true)
      .catch((err) => false);
   debugger;
    this.updateCount()
    return res;
  }

  
}

export default FireBaseUserService;
