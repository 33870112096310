import { faqs } from "../../data/mock";
import star from "../../assets/images/star.png";
import { LargeHeading } from "../shared/typography";
import FAQItem from "./FAQItem";
import { mainMintDecision } from "../../helpers/utils";

const FAQList = (props) => {
  return (
    <div
      id="faq"
      className="bg-[#272D6F] text-white p-4 lg:p-24 text-center relative"
    >
      <img
        src={star}
        alt=""
        className="absolute top:6 right-6  md:top-16 md:right-16"
      />
      <img src={star} alt="" className="absolute top-1/2 left-12" />
      <LargeHeading>FAQ's</LargeHeading>
      <div className="grid gap-10 mt-5">
        {faqs.map((faq) => {
          return <FAQItem faq={faq} key={faq.question} />;
        })}
        <button
                className="top_right_section_buttons forth_font_card_header"
                onClick={mainMintDecision}
              >
                <p className="forth_font_card_header">Go to Mint</p>
              </button>
      </div>
    </div>
  );
};

export default FAQList;
