import { LargeHeading, SubHeading } from "./typography";
import flag_moon_man from "../../assets/images/flag_moon_man.png";
import fishing from "../../assets/images/fishing.png";
import curve2 from "../../assets/images/curve2.png";
import curve from "../../assets/images/curve.png";
import star from "../../assets/images/star.png";
import night from "../../assets/images/night.png";
import RarityList from "./RarityList";

const About = () => {
  return (
    <div id="about" className="overflow-hidden w-full">
      {/* section 1 */}
      <div className="bg-primary-light flex flex-col lg:flex-row pt-10 lg:pt-52 gap-4 lg:gap-8  lg:justify-end ">
        <div className="text-center space-y-6 p-4 relative">
          <div className="absolute top-0 left-2 scale-75 lg:scale-100 2xl:-left-[100px]  ">
            <img src={star} alt="" className="m-2 ml-4" />
            <img src={star} alt="" className="scale-90" />
          </div>
          <LargeHeading>Who are DegenMoonFrens?</LargeHeading>
          <SubHeading className="max-w-5xl lg:pb-32 2xl:pb:10">
            DegenMoonFrens is a collection of 4,444 frens who live by the night,
            flipping NFTs and building bonds with strangers over the internet.
            Our goal is to create a highly positive community of builders and
            artists who want to see the NFT world thrive. We strive to deliver
            good vibes, great alpha, and kickass live events and merch where all
            our frens can get together and partake in degen shenanigans.
          </SubHeading>
        </div>
        <img src={flag_moon_man} alt="flag moon man" className="self-end" />
      </div>
      {/* section 2 */}
      <div className="pb-[150px] lg:pb-[300px] bg-[#F4F4F4] relative">
        <img
          src={curve2}
          alt=""
          className="absolute top-0 scale-105  -translate-y-full w-full"
        />

        <div className="flex flex-col items-center justify-start p-4 lg:p-12 2xl:p-24 text-center gap-4 lg:gap-6">
          <LargeHeading className="w-[290px] md:w-[400px] lg:w-full">
            Why Degen? Why Moons? Why Frens?
          </LargeHeading>
          <SubHeading className="lg:max-w-5xl 2xl:w-full">
            The Degen Moon Frens are a doxxed team of 3 crypto natives aiming to
            redefine the standard of quality and transparency in the PFP NFT
            space. Our mission is to create a welcoming onboarding experience
            for NFT newcomers while delivering substantial value back to our
            holders, new and experienced alike. Our custom built “InviteList”
            breaks the mold of whitelist grinding, as we don’t want to waste
            your time performing superfluous tasks. Get invited and invite your
            frens to create a super community of alpha sharing degens.
          </SubHeading>
          {/* <LargeHeading>Community</LargeHeading>
          <SubHeading className="lg:max-w-5xl 2xl:w-full">
            In the NFT Space the word “community” is commonly thrown around, and
            rightfully so as it is the binding lifeline that drives a project’s
            success. People want to join a community to feel like they are part
            of something, but oftentimes still feel alone in that crowded space.
            We’ve developed a unique invite system to alleviate this and bring
            our friends closer than ever. Each community member will be given 2
            friend invitations to give out to whomever they choose until our
            Invite List hits capacity. Everyone on the list will be eligible to
            mint 3 degen moon frens during the presale, one of which is a gift,
            costing nothing, as we encourage our members to send this free mint
            to a friend or loved one.
          </SubHeading>
          <LargeHeading>Education</LargeHeading>
          <SubHeading className="lg:max-w-5xl 2xl:w-full">
            We believe that education is a core element to personal growth and
            want to help foster this in our community. We plan on conducting
            educational webinars on the topics of web development, blockchain &
            web3, and artistic development. We already have 3 developers that
            will lead “Intro to Web-dev” classes and will hire additional
            contract staff to teach classes as needed. Our co-founder bsmokes
            has a depth of blockchain knowledge and connections he will leverage
            to conduct regular classes and spaces. We also have a deep community
            of artists, led by 4thCulture, ready to help people expand their
            skill sets and abilities to deploy in the web3 space.
          </SubHeading>
          <LargeHeading>Alpha</LargeHeading>
          <SubHeading className="max-w-3xl 2xl:ml-16">
            Together we raise. This is a team sport and we are leveraging existing networks
            along with placing the right people in the right positions to provide the community
            with A1 calls, direction, clarity, and support.
          </SubHeading> */}
        </div>
      </div>
      {/* section 3 */}
      <div className="relative bg-black text-white flex flex-col items-center gap-4 p-4 lg:pb-20 text-center w-full">
        <img
          src={curve}
          alt=""
          className="absolute top-0 scale-105 -translate-y-full w-full object-cover "
        />

        <img
          src={star}
          alt=""
          className="absolute hidden top-[30%] left-[5%] scale-75 lg:block"
        />
        <img
          src={star}
          alt=""
          className="absolute hidden bottom-[2%] left-[30%] scale-75 lg:block"
        />
        <div className="absolute top-2 lg:top-12 -left-2 scale-50 lg:scale-100 lg:left-[88%] flex items-start gap-4">
          <img src={star} alt="" className="" />
          <img src={night} alt="" className="" />
        </div>
        <img
          src={fishing}
          alt=""
          className="absolute left-[0] top-0 -translate-y-3/4 w-[235px] lg:w-[580px]  2xl:w-[980px]"
        />
        <LargeHeading>Rarirty</LargeHeading>
        <SubHeading>
          Each NFT is generated by combining 200 unique traits with varying
          rarity.
        </SubHeading>
        <RarityList />
      </div>
    </div>
  );
};

export default About;
