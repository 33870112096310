import About from "../components/shared/About";
import DegenList from "../components/team/DegenList";
import RoadMap from "../components/shared/RoadMap";
import FAQList from "../components/faq/FAQList";
import Hero from "../components/shared/Hero";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <RoadMap />
      <DegenList />
      <FAQList />
    </>
  );
};

export default Home;
