const TwitterIcon = (props) => {
  return (
    <svg width="46.488" height="37.757" viewBox="0 0 46.488 37.757" {...props}>
      <path
        d="M41.709,12.79c.03.413.03.826.03,1.239,0,12.6-9.587,27.108-27.108,27.108A26.924,26.924,0,0,1,0,36.86a19.71,19.71,0,0,0,2.3.118,19.081,19.081,0,0,0,11.828-4.071A9.544,9.544,0,0,1,5.221,26.3a12.015,12.015,0,0,0,1.8.147,10.077,10.077,0,0,0,2.507-.324,9.529,9.529,0,0,1-7.64-9.351v-.118a9.6,9.6,0,0,0,4.307,1.209A9.542,9.542,0,0,1,3.245,5.121a27.082,27.082,0,0,0,19.645,9.97,10.755,10.755,0,0,1-.236-2.183A9.537,9.537,0,0,1,39.143,6.389a18.758,18.758,0,0,0,6.047-2.3A9.5,9.5,0,0,1,41,9.339a19.1,19.1,0,0,0,5.486-1.475,20.481,20.481,0,0,1-4.778,4.926Z"
        transform="translate(0 -3.381)"
      />
    </svg>
  );
};

export default TwitterIcon;
