import { MerkleTree } from "merkletreejs";
import {keccak256} from "ethers/lib/utils";
import addresses from "./addresses";
import {Buffer} from 'buffer';
window.Buffer = Buffer;

const leafNodes = addresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});


const rootHash = merkleTree.getRoot();
const hexProof = merkleTree.getHexProof(leafNodes[0]);
export {rootHash, hexProof, addresses, leafNodes, merkleTree};