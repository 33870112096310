import { rarities } from "../../data/mock";
import { Heading, SubHeading } from "./typography";
import "../../styles/app.css";

const RarityList = () => {
  return (
    <div
      // className="flex flex-wrap justify-center gap-2 md:gap-6 mt-4 md:mt-12 text-left"
      className="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 2xl:gap-6 mt-4 md:mt-12 text-left"
    >
      {rarities.map((rarity) => {
        return (
          <div
            className="rounded-3xl overflow-hidden max-w-[365px]"
            key={rarity.color}
          >
            <img src={rarity.image} alt={rarity.rarity} />
            <div className="bg-white text-black p-3 md:p-6 ">
              <div className="flex justify-between items-center w-full gap-2">
                <SubHeading className="font-semibold">
                  {rarity.color}
                </SubHeading>
                <SubHeading className="bg-primary-light rounded-xl pt-3 pb-1 uppercase px-2 truncate">
                  {rarity.rarity}
                </SubHeading>
              </div>
              <SubHeading className="lg:text-4xl ">
                QTY {rarity.quantity}
              </SubHeading>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RarityList;
