import { useContext } from "react";

import { UserContext } from "../../providers/UserContextProvider";
import logo from "../../assets/images/Base_flat.png";
import MenuIcon from "./icons/Menu";
import SocialLinks from "./SocialLinks";
import { useDrawerContext } from "../../hooks/useContext";

import "../../styles/app.css";

export const Header = (props) => {
  const { connectWalletHandler } = useContext(UserContext);
  const { onOpen, isOpen } = useDrawerContext();
  const { mint } = props;
  return (
    <header
      className={`${
        isOpen ? "-z-10" : "z-10"
      } top-0 sticky w-full bg-white bg-opacity-70  px-5 lg:px-12"`}
    >
      <nav className="h-[95px] lg:h-[150px] flex  justify-between items-center">
        <a className="" href="/">
          <img
            src={logo}
            alt=""
            className=" h-[80px] w-[80px] lg:w-[142px] lg:h-[142px] "
          />
        </a>

       {!mint ? <ul className="hidden lg:flex text-3xl font-bold space-x-5 forth_font_card_header">
          <li className="hover:text-primary-light">
            <a aria-current="page" href="#home">
              HOME
            </a>
          </li>
          <li className="hover:text-primary-light">
            <a href="#about">ABOUT</a>
          </li>

          <li className="hover:text-primary-light">
            <a href="#map">ROADMAP</a>
          </li>
          <li className="hover:text-primary-light">
            <a href="#team">FAMILY</a>
          </li>
          <li className="hover:text-primary-light">
            <a href="#faq">FAQ</a>
          </li>
          <li className="hover:text-primary-light">
            <a href="/mint">MINT</a>
          </li>
        </ul> : ""}

        <div className="hidden lg:flex">
          <SocialLinks />
        </div>
      { !mint ? <button className="lg:hidden" area-label="open drawer" onClick={onOpen}>
          <MenuIcon />
        </button> : ""}
      </nav>
    </header>
  );
};

export default Header;
