import Modal from "react-modal";

import moon from "../../assets/images/moon_dark.png";
import { useDrawerContext } from "../../hooks/useContext";
import AddIcon from "./icons/Add";
import SocialLinks from "./SocialLinks";

Modal.setAppElement("#root");

const Drawer = (props) => {
  const { isOpen, onClose } = useDrawerContext();
  const { mint } = props
  if(mint){
    return "";
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="w-[100vw] h-[100vh] touch-none"
      contentLabel="Drawer"
      parentSelector={() => document.querySelector("#portal")}
      preventScroll
    >
      <div
        className={`bg-white bg-opacity-80 w-full h-full flex flex-col gap-16 items-center justify-center`}
      >
        <button
          area-label="close drawer"
          className="absolute top-0 right-0 m-5"
          onClick={onClose}
        >
          <AddIcon className="rotate-45" />
        </button>

        <ul className="flex flex-col text-3xl font-bold gap-6 font-roboto items-center">
          <li>
            <a
              aria-current="page"
              href="#home"
              className="hover:text-primary-light"
              onClick={onClose}
            >
              HOME
            </a>
          </li>
          <li>
            <a
              className="hover:text-primary-light"
              href="#about"
              onClick={onClose}
            >
              ABOUT
            </a>
          </li>

          <li>
            <a
              className="hover:text-primary-light"
              href="#map"
              onClick={onClose}
            >
              ROADMAP
            </a>
          </li>
          <li>
            <a
              onClick={onClose}
              className="hover:text-primary-light"
              href="#team"
            >
              FAMILY
            </a>
          </li>
          <li>
            <a
              className="hover:text-primary-light"
              href="#faq"
              onClick={onClose}
            >
              FAQ
            </a>
          </li>
          <li>
            <a
              className="hover:text-primary-light"
              href="/mint"
              onClick={onClose}
            >
              MINT
            </a>
          </li>
        </ul>
        <SocialLinks />
        <img src={moon} alt="moon footer" className="" />
      </div>
    </Modal>
  );
};

export default Drawer;
