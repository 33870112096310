import { useState } from "react";

import AddIcon from "../shared/icons/Add";
import MinusIcon from "../shared/icons/Minus";
import { Heading, SubHeading } from "../shared/typography";

const FAQItem = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className=" bg-white  text-black border-4 border-black py-7 rounded-2xl px-4 lg:px-16 text-left  transition-all overflow-hidden">
      <div className="flex justify-between items-center gap-2">
        <Heading className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          {faq.question}
        </Heading>
        <button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <MinusIcon /> : <AddIcon />}
        </button>
      </div>
      {isOpen && <SubHeading className="mt-4">{faq.answer}</SubHeading>}
    </div>
  );
};

export default FAQItem;
