import roadmap from "../../assets/images/moon_map.png";
import roadmap_mobile from "../../assets/images/mobile_moon_map.png";
import meteoroid from "../../assets/images/meteoroid.png";
import moon from "../../assets/images/moon_dark.png";
import { SubHeading } from "./typography";

const RoadMap = (props) => {
  return (
    <div id="map" className="relative mb-12 lg:mb-0 w-full">
      <img
        src={meteoroid}
        alt=""
        className="absolute top-0 right-0 -translate-y-3/4 w-[166px] lg:w-[420px]"
      />
      <img src={roadmap} alt="road map" className="w-full hidden lg:block" />
      <img src={roadmap_mobile} alt="road map" className="w-full  lg:hidden" />
      <button className="absolute bottom-0 right-2 md:right-5 translate-y-1/2 bg  overflow-hidden ">
        <div className="hover:rotate-6 transition flex justify-center items-center">
          <img src={moon} alt="" className="w-[100px] md:w-[200px] " />
          <div className="w-[85%] h-[85%] absolute bg-white rounded-full p-2 text-center flex items-center">
            <SubHeading className="line-clamp-3 scale-90">
              Click here for more info!
            </SubHeading>
          </div>
        </div>
      </button>
    </div>
  );
};

export default RoadMap;
