const faqs = [
  {
    question: "What are degen moon frens?",
    answer:
      "Our frens are part of the tightest group of frens in the nft-verse (what’s a meta). We do this with our innovative whitelist system in which each invited member will be able to invite up to 3 additional frens to all receive a free mint. That’s right, free. This ensures that our network starts with everyone connected to everyone via a series of mutual frens. We then consolidate NFT alpha, news, and education for all of our members.",
  },
  {
    question: "What does my degen moon fren get me?",
    answer:
      "Community. Education. Alpha. A DegenMoonFren give holders access to the first community-curated community, Moon University a Web3 skills alternative education program, Many IRL Events, and Alpha from professional Alpha callers.",
  },
  {
    question: "What's the total supply?",
    answer: "4,444",
  },
  {
    question: "Whats the mint price?",
    answer: "0.02E",
  },
  {
    question: "How does AllowList work?",
    answer:
      "No AllowList, only community list. All community members will be added by the community, prior to launch we aggregated 888 close friends, and friends of friends, they all receive 2 invites, and the friends they invite will receive 2 additional invites; If you’re one of the first in our discord, you’ve made it.",
  },
  {
    question: "How many traits are there?",
    answer:
        "200 Traits"
        },
  {
    question: "Why isn’t your roadmap full of bullshit?",
    answer:
"Because we don’t want to promise anything we won’t keep. Our plan is to grow this community and give back to it in whichever ways we can. This will start with live events and merch. We are in this for the long haul and want to see how the community develops and make decisions on what it needs to continue to thrive. We want to remain tactical with how we use project funds to not squander it. Although I would expect a few coordinated NFT giveaways ;)"  },
  
];

const rarities = [
  {
    color: "Cold Moon",
    image: require("../assets/images/gray_moon.png"),
    quantity: 2222,
    rarity: "Common",
  },
  {
    color: "Harvest Moon",
    image: require("../assets/images/yellow_moon.png"),
    quantity: 1056,
    rarity: "Rare",
  },
  {
    color: "Blood Moon",
    image: require("../assets/images/red_moon.png"),
    quantity: 722,
    rarity: "Epic",
  },
  {
    color: "Blue Moon",
    image: require("../assets/images/blue_moon.png"),
    quantity: 444,
    rarity: "Mystic",
  },
];

const degens = [
  {
    name: "Reuel Tan",
    role: "Co-Founder / Artist",
    image: require("../assets/images/4th.png"),
    color: "bg-[#DB5A30]",
    twitter: "4th_Culture",
    url: "https://twitter.com/4thCulture_",
  },
  {
    name: "Brian Smocovich",
    role: "Co-Founder / Business Development",
    image: require("../assets/images/degen3.png"),
    color: "bg-[#B28DFC]",
    twitter: "BSmokes_",
    url: "https://twitter.com/BSmokes_",
  },
  {
    name: "Anthony Johnson",
    role: "Co-Founder / Developer",
    color: "bg-[#FF4B61]",
    image: require("../assets/images/degen2.png"),
    twitter: "AntDJohns",
    url: "https://twitter.com/AntDJohns",
  },
  
];

export { faqs, rarities, degens };
