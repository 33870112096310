const LargeHeading = (props) => {
  const { children, className, ...rest } = props;
  const defaultClasses = "font-c4th text-5xl  md:text-7xl lg:text-8xl";

  return (
    <h1
      className={className ? defaultClasses + " " + className : defaultClasses}
      {...rest}
    >
      {children}
    </h1>
  );
};

const Heading = (props) => {
  const { children, className, ...rest } = props;
  const defaultClasses = "font-c4th text-xl md:text-3xl lg:text-5xl";
  return (
    <h2
      className={className ? defaultClasses + " " + className : defaultClasses}
      {...rest}
    >
      {children}
    </h2>
  );
};

const SubHeading = (props) => {
  const { children, className, ...rest } = props;
  const defaultClasses = "font-c4th text-md md:text-2xl lg:text-4xl";
  return (
    <h4
      className={className ? defaultClasses + " " + className : defaultClasses}
      {...rest}
    >
      {children}
    </h4>
  );
};

export { LargeHeading, Heading, SubHeading };
