import { degens } from "../../data/mock";
import { LargeHeading } from "../shared/typography";
import DegenItem from "./DegenItem";

const DegenList = (props) => {
  return (
    <div id="team" className="grid place-items-center p-4 lg:p-12 ">
      <LargeHeading>Meet the Degens</LargeHeading>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 place-items-center gap-6 mt-4">
        {degens.map((degen, index) => {
          return <DegenItem degen={degen} key={index} />;
        })}
      </div>
    </div>
  );
};

export default DegenList;
