import { useState, useEffect } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  isPaused,
  isWLMint,
  totalSupply,
  getPrice,
  mint,
} from "./interact.js";
import gif from "../assets/images/gif_mint.gif";
import {
  LargeHeading,
  SubHeading,
  Heading,
} from "../components/shared/typography/index.jsx";

const Mint = () => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState(false);
  const [tokensToMint, setTokensToMint] = useState(1);
  const [cost, setCost] = useState(0.02);
  const [total, setTotal] = useState(0);
  const [paused, setPaused] = useState(true);
  const [isWhitelist, setIsWhitelist] = useState(false);
  const [mintStatus, setMintStatus] = useState("Mint");
  const [checked, setChecked] = useState(false);

  const onChange = async ({ target }) => {
    setTokensToMint(target.value);
  };

  const setCostAmount = async () => {
    const cost = await getPrice();
    setCost((cost * tokensToMint).toFixed(2));
  };

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(true);
        } else {
          setWallet("");
          setStatus(false);
        }
      });
    } else {
      setStatus(
        false
      );
    }
  }

  useEffect(async () => {
    //TODO: implement
    const { address, status } = await getCurrentWalletConnected();
    const total = await totalSupply();
    const wlmint = await isWLMint();
    const paused = await isPaused();
    const cost = await getPrice();
    setWallet(address);
    setStatus(status);
    setTotal(total);
    setPaused(paused);
    setIsWhitelist(wlmint);
    if (wlmint || !paused) {
      setCost((cost * tokensToMint).toFixed(2));
    } else {
      setCost(cost.toFixed(2));
    }

    addWalletListener();
  }, []);

  useEffect(() => {

  }, [status])

  useEffect(() => {
    if (isWhitelist || !paused) {
      setCostAmount();
    }
  }, [tokensToMint]);

  useEffect(() => {}, [isWhitelist]);

  const connectWalletPressed = async () => {
    //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    //TODO: implement
    await mintNFT();
  };

  return (
    <>
      <div
        className="grid justify-center  bg-[#272D6F]"
        style={{ backgroundColor: "#272D6F" }}
      >
        <div className="flex justify-center p-5 text-white">
          <LargeHeading> Are you Degen?</LargeHeading>
        </div>
        <div className="flex justify-center">
          <img
            src={gif}
            className="sm:w-64 lg:w-96 w-64 rounded m-5"
            alt="rotation of moon frens"
          />
        </div>
        {/*
       WhiteList Mint Selection
      */}
        {isWhitelist ? (
          <div className="flex justify-center" id="wl-mint">
            <div className="selector">
              <div class="inline-block relative w-64" id="public-mint">
                <select
                  onChange={onChange}
                  class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline font-c4th text-md md:text-2xl lg:text-4xl"
                >
                  <option value={4}>5x 🚀</option>
                  <option value={3}>4x</option>
                  <option value={2}>3x</option>
                  <option value={1}>2x</option>
                  <option value={0}>1(free)</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : !paused ? (
          <div className="flex justify-center" id="public">
            <div className="selector">
              <div class="inline-block relative w-64">
                <select
                  onChange={onChange}
                  class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline font-c4th text-md md:text-2xl lg:text-4xl"
                >
                  <option value={1}>1x</option>
                  <option value={2}>2x</option>
                  <option value={3}>3x</option>
                  <option value={4}>4x</option>
                  <option value={5}>5x</option>
                  <option value={6}>6x</option>
                  <option value={7}>7x</option>
                  <option value={8}>8x</option>
                  <option value={9}>9x</option>
                  <option value={10}>10x</option>
                  <option value={11}>11x</option>
                  <option value={12}>12x</option>
                  <option value={13}>13x</option>
                  <option value={14}>14x</option>
                  <option value={15}>15x</option>
                  <option value={16}>16x</option>
                  <option value={17}>17x</option>
                  <option value={18}>18x</option>
                  <option value={19}>19x</option>
                  <option value={20}>20x 🚀</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-center text-red-600">
              <Heading>Coming Soon</Heading>
            </div>
          </>
        )}

        <div className="flex justify-center">
          {status ? (
            <button
              disabled={!checked}
              onClick={async () => {
                if (isWhitelist && checked) {
                  let { success, status } = await mintNFT(tokensToMint);
                  if (success) {
                    setTimeout(() => {
                      setMintStatus("Success");
                    }, 2000);
                    setTimeout(() => {
                      setMintStatus("Mint");
                    }, 2000);
                  } else {
                    setTimeout(() => {
                      setMintStatus("Unsuccessful");
                    }, 2000);
                    setTimeout(() => {
                      setMintStatus("Mint");
                    }, 2000);
                  }
                } else if (!paused && checked) {
                  let { success, status } = await mint(tokensToMint);
                  if (success) {
                    setTimeout(() => {
                      setMintStatus("Success");
                    }, 2000);
                    setTimeout(() => {
                      setMintStatus("Mint");
                    }, 2000);
                  } else {
                    setTimeout(() => {
                      setMintStatus("Unsuccessful");
                    }, 1000);
                    setTimeout(() => {
                      setMintStatus("Mint");
                    }, 2000);
                  }
                } else {
                  alert("Mint is not is Session");
                }
              }}
              className={`rounded ${ checked ? "bg-[#FFFFFF]" : "bg-[#F0F0F0]" } w-64`}
            >
              <SubHeading>{mintStatus}</SubHeading>
            </button>
          ) : (
            <button
              onClick={connectWallet}
              className="rounded bg-[#FFFFFF] w-64"
            >
              <SubHeading>Connect Wallet</SubHeading>
            </button>
          )}
        </div>
        <div className="flex justify-center pt-4 m-1">
        <input
            id="checked-checkbox"
            type="checkbox"
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        <label
            htmlFor="checked-checkbox "
            className="ml-2 text-sm font-medium text-white"
          >
          <p style={{lineHeight: "0"}}>
            <small style={{lineHeight: "0"}}>I have read and agree with the terms of</small></p>
             
              <small style={{lineHeight: "0"}}> service and privacy statement.</small>
          </label>
        </div>
        <div className="flex justify-center pt-1 text-white">
          <a href="/terms_of_service" target="_blank">
            Terms Of Service
          </a>{" "}
          &nbsp;-&nbsp;{" "}
          <a href="/privacy_statement" target="_blank">
            Privacy Statement
          </a>
        </div>
        <div className="flex justify-center pt-1"></div>
        <div className="flex justify-center pt-2 text-white">
          <SubHeading>Cost: {cost}E</SubHeading>
        </div>
        <div className="flex justify-center p-2 text-white">
          <SubHeading>Remaining DMFS {4444 - total}</SubHeading>
        </div>
        <div className="grid row-3">
          <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
            {/*Card 1*/}
            <div className="rounded overflow-hidden shadow-lg bg-[#FFFFFF]">
              <img
                className="w-full"
                src={require("../assets/images/degenfren_main.png")}
                alt="Mountain"
              />
              <div className="px-6 py-4 text-center">
                <Heading>COMMUNITY</Heading>
                <ul>
                  <li className="p-1">
                    <SubHeading>- Micro Events Across the Globe</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>- Annual Degen Week</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>
                      - IRL Support from team and Degen Community
                    </SubHeading>
                  </li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-2"></div>
            </div>
            {/*Card 2*/}
            <div className="rounded overflow-hidden shadow-lg bg-[#FFFFFF]">
              <img
                className="w-full p-5 rounded"
                src={require("../assets/images/education.png")}
                alt="River"
              />
              <div className="px-6 py-4 text-center">
                <Heading>EDUCATION</Heading>
                <ul>
                  <li className="p-1">
                    <SubHeading>- All things Crypto Education</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>
                      - Instructor lead Bootcamps for Web3 valuable skills
                    </SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>
                      - Career support, Resume review, Coaching
                    </SubHeading>
                  </li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-2"></div>
            </div>
            {/*Card 3*/}
            <div className="rounded overflow-hidden shadow-lg bg-[#FFFFFF]">
              <img
                className="w-full p-5"
                src={require("../assets/images/alpha.png")}
                alt="Forest"
              />
              <div className="px-6 py-4 text-center">
                <Heading>ALPHA</Heading>
                <ul>
                  <li className="p-1">
                    <SubHeading>- Weekly Alpha Digest</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>- Oncall experts</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>- Collection Investigation</SubHeading>
                  </li>
                  <li className="p-1">
                    <SubHeading>
                      - Commuinity and Staff Updates and calls
                    </SubHeading>
                  </li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mint;
