import React from "react";
import { Route, Routes } from "react-router";

import Home from "./pages/Home";
import Invite from "./pages/Invite";
import Layout from "./components/shared/Layout";
import Mint from "./pages/Mint.jsx";
import DevTest from "./pages/DevTest";
import Privacy from "./pages/privacy";
import TOS from "./pages/tos";

function App() {
  return (
    <Routes>
      <Route
        path="/invite/:address"
        element={
          <Layout>
            <Invite />
          </Layout>
        }
      />
      <Route
        path="/mint"
        element={
          <Layout mint>
            <Mint />
          </Layout>
        }
      />
            <Route
        path="/administrator/dev"
        element={
            <DevTest />
        }
      />
      <Route
        path="*"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
            <Route
        path="/privacy_statement"
        element={
          <Layout mint>
            <Privacy />
          </Layout>
        }
      />
            <Route
        path="/terms_of_service"
        element={
          <Layout mint>
            <TOS />
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
