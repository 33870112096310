import TwitterIcon from "./icons/Twitter";
import OpenseaIcon from "./icons/Opensea";
import DiscordIcon from "./icons/Discord";
import InstagramIcon from "./icons/Instagram";

const discordLink = "https://discord.gg/degenmoonfrens";
const twitterLink = "https://twitter.com/DegenMoonFrens";
const instagramLink = "https://instagram.com/degenmoonfrens";
const openseaLink = "https://opensea.io/collection/degenmoonfrens";

const SocialLinks = (props) => {
  const { small = false } = props;

  const size = small ? "w-8 h-8" : "w-14 h-14";

  return (
    <ul className="flex items-center space-x-4">
      <li className="">
        <a href={twitterLink} target="_blank" rel="noreferrer">
          <TwitterIcon
            className={`hover:fill-primary-light  lg:w-14 lg:h-14 ${size}`}
          />
        </a>
      </li>
      <li className="">
        <a href={openseaLink} target="_blank" rel="noreferrer">
          <OpenseaIcon
            className={`hover:fill-primary-light lg:w-14 lg:h-14   ${size}`}
          />
        </a>
      </li>
      <li className="">
        <a href={discordLink} target="_blank" rel="noreferrer">
          <DiscordIcon
            className={`hover:fill-primary-light lg:w-14 lg:h-14  ${size}`}
          />
        </a>
      </li>

      <li className="">
        <a href={instagramLink} target="_blank" rel="noreferrer">
          <InstagramIcon
            className={`hover:fill-primary-light lg:w-14 lg:h-14   ${size}`}
          />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
