import TwitterIcon from "../shared/icons/Twitter";
import { Heading, SubHeading } from "../shared/typography";

const DegenItem = (props) => {
  const { degen } = props;
  return (
    <div
      className={`${degen.color} cursor-pointer rounded-2xl p-4 pb-0 overflow-hidden hover:shadow-lg hover:scale-105 transition flex flex-col justify-between max-w-lg`}
    >
      <Heading className="uppercase">{degen.name}</Heading>
      <div className="flex justify-between ">
        <div className="flex flex-col justify-between">
          <SubHeading className="">{degen.role}</SubHeading>
         <a href={degen.url} target="_blank"> <div className="flex mb-2 gap-2">
          
             <TwitterIcon className="fill-transparent stroke-2 stroke-black w-6 h-6 " />
          
            <p className="font-c4th lg:text-2xl truncate text-ellipsis">
              {degen.twitter}
            </p>
          </div>
          </a> 
        </div>
        <img
          src={degen.image}
          alt={degen.name}
          className="w-[200px] h-[210px] lg:w-[248px] lg:h-[270px] mt-auto"
        />
      </div>
    </div>
  );
};

export default DegenItem;
