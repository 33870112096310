import { useState, useEffect } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  setWhitelistMintEnabled,
  setPaused,
  addRootHash,
  mintNFT,
  isPaused,
  isWLMint,
  totalSupply,
  getPrice,
  setCost,
  setRevealed,
  setUriPrefix,
  mint,
  withdraw,
} from "./interact.js";

const DevTest = () => {
  const [revelaed, _setRevealed] = useState(false);
  const [uriPrefix, _setUriPrefix] = useState("");
  const [paused, _setPaused] = useState(false);
  const [mintAmount, _setMint] = useState(0);
  
    function addWalletListener() {
    // if (window.ethereum) {
    //   window.ethereum.on("accountsChanged", (accounts) => {
    //     if (accounts.length > 0) {
    //       setWallet(accounts[0]);
    //       setStatus("👆🏽 Write a message in the text-field above.");
    //     } else {
    //       setWallet("");
    //       setStatus("🦊 Connect to Metamask using the top right button.");
    //     }
    //   });
    // } else {
    //   setStatus(
    //     <p>
    //       {" "}
    //       🦊{" "}
    //       <a target="_blank" href={`https://metamask.io/download.html`}>
    //         You must install Metamask, a virtual Ethereum wallet, in your
    //         browser.
    //       </a>
    //     </p>
    //   );
    // }
  }

  useEffect(async () => {
    //TODO: implement
    const { address, status } = await getCurrentWalletConnected();
    console.log(address, "-<>- ", status);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    //TODO: implement
    const walletResponse = await connectWallet();
    // setStatus(walletResponse.status);
    // setWallet(walletResponse.address);
  };
  return (
    <>
        <button onClick={connectWalletPressed}>Connect</button>
      <form id="set-revealed">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            setRevealed()
          </label>
          <input
            type="text"
            value={revelaed}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={async () => {
              await setRevealed(true);
              _setRevealed(true);
          }}
        >
          SET REVEALED
        </button>
      </form>
      <form id="setUri">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            setUriPrefix()
          </label>
          <input
            type="text"
            value={uriPrefix}
            onChange={(e) => {
                const target = e.target;
                _setUriPrefix(target.value)
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          onClick={() => {
              setUriPrefix(uriPrefix);
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          SET uriPrefix
        </button>
      </form>
      <form id="setPublic">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            setPaused()
          </label>
          <input
            type="text"
            value={paused}
            onChange={(e) => {
                const target = e.target;
                const res = target.value;
                if(res.toLocaleLowerCase() == "true"){
                  setPaused(true);
                }else{
                  setPaused(false);
                }
                
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          onClick={() => {
              setPaused(paused);
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          SET Paused
        </button>
      </form>
      <form id="mint">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            mint()
          </label>
          <input
            type="text"
            value={mintAmount}
            onChange={(e) => {
                if(!isNaN(Number(e.target.value))){
                  _setMint(Number(e.target.value))
                }
                
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          onClick={() => {
              mint(mintAmount);
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Mint
        </button>
      </form>
      <form id="withdraw">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            withdraw()
          </label>
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          onClick={() => {
              withdraw();
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Mint
        </button>
      </form>
      <form id="addRootHash">
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            addRootHash()
          </label>
        </div>
        <div className="flex items-start mb-6"></div>
        <button
          type="button"
          onClick={() => {
              addRootHash();
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Add Root Hash
        </button>
      </form>
    </>
  );
};

export default DevTest;
