import React, { createContext, useState, useEffect } from "react";
import mobileCheck from "../helpers/mobileCheck";
import getLinker from "../helpers/deepLink";
import abi from "../abis/MoonFrens.json";

// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [userAddress, setUserAddress] = useState(null);
  const [inviter, setInviter] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);

  const connectWalletHandler = async () => {
    const deepLink = "https://metamask.app.link/dapp/degenmoonfrens.xyz/mint";
    const downloadMetamaskUrl = "https://metamask.io/download.html";

    if (window.ethereum) {
      let ethers = require("ethers");
      let ethers_provider = await new ethers.providers.Web3Provider(
        window.ethereum
      );
      let address = await ethers_provider.send("eth_requestAccounts", []);
      setUserAddress(address[0])
    } else if (mobileCheck()) {
      const linker = getLinker(downloadMetamaskUrl);
      linker.openURL(deepLink);
    } else {
      window.open(downloadMetamaskUrl);
    }
  };

  const mainMintDecision = async () => {
    const deepLink = "https://metamask.app.link/dapp/degenmoonfrens.xyz/mint";
    const downloadMetamaskUrl = "https://metamask.io/download.html";

    if (window.ethereum) {
      window.location.replace("https://www.degenmoonfrens.xyz/mint");
    } else if (mobileCheck()) {
      const linker = getLinker(downloadMetamaskUrl);
      linker.openURL(deepLink);
    } else {
      window.open(downloadMetamaskUrl);
    }
  };


  const attachContract = async () => {
    const ethers = require("ethers");
    let provider = await new ethers.providers.Web3Provider(
      window.ethereum
    );
    const moonFrenContract = new ethers.Contract(
      "0xAC7DAf603Cc751C91e3513b6FB33Bd7157ffFf61",
      abi,
      provider
    );
    setContract(moonFrenContract);
  };

  // fetch a user from a fake backend API
  useEffect(() => {}, []);

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider
      value={{
        userAddress, 
        signer,
        inviter,
        contract,
        attachContract,
        connectWalletHandler,
        setInviter,
        setUserAddress,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
