import { formatEther } from "ethers/lib/utils";
import keccak256 from "keccak256";
import { merkleTree, rootHash } from "../data/merkle-tree";
import { toMetaMaskMint } from "../helpers/utils";

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../abis/MoonFrens.json");
const contractAddress = "0xFe41d6BA0B87056FAa087FD93F68c4f9A3E683E7";

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: true,
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: false,
      };
    }
  } else {
    toMetaMaskMint();
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: true,
        };
      } else {
        return {
          address: "",
          status: false,
        };
      }
    } catch (err) {
      return {
        address: "",
        status: false,
      };
    }
  } else {
    return {
      address: "",
      status: false,
    };
  }
};

export const mintNFT = async (tokensToMint) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const { address } = await getCurrentWalletConnected();
  let value = await getPrice() * tokensToMint;
  const hexProof = merkleTree.getHexProof(keccak256(window.ethereum.selectedAddress))
  const wei = web3.utils.toWei(value.toString(), 'ether')
  
  const transactionParameters = {
    to: contractAddress,
    from: address,
    value: web3.utils.toHex(wei),
    data: window.contract.methods
      .whitelistMint(
        tokensToMint,
        hexProof
      ).encodeABI()
  }

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
      chain: "rinkeby"
    }, function(error, hash){
        if(!error){
            console.log("success");
        }else {
            console.log("PROMISE: ", error);
        }
    });
   
    return {
      success: true,
      status: `Success`
  }
  } catch (error) {
      console.log(error)
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const mint = async (tokensToMint) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const { address } = await getCurrentWalletConnected();
  let value = await getPrice() * tokensToMint;
  const wei = web3.utils.toWei(value.toString(), 'ether')
  console.log("Public Minting",)
  const transactionParameters = {
    to: contractAddress,
    from: address,
    value: web3.utils.toHex(wei),
    data: window.contract.methods
      .mint(
        tokensToMint
      ).encodeABI()
  }

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    }, function(error, hash){
        if(!error){
            return hash;
        }else {
            console.log("PROMISE: ", error);
        }
    });
    console.log("TXN", txHash)
    return {
        success: true,
        status: `Success, Check Status at ${txHash}`
    }

  } catch (error) {
      console.log("Error", error)
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const setWhitelistMintEnabled = async (enabled) => {
  console.log("Setting Whitelist Enabled");
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setWhitelistMintEnabled(enabled).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const setPaused = async (paused) => {
  console.log("Setting Pause Contract status");
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setPaused(paused).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const addRootHash = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setMerkleRoot(rootHash).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const setUriPrefix = async (uriPrefix) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setUriPrefix(uriPrefix).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const setRevealed = async (revealed) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setRevealed(revealed).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const setCost = async (cost) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.setCost(cost).encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const withdraw = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    data: window.contract.methods.withdraw().encodeABI(), //make call to NFT smart contract
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
}

export const getPrice = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const cost = await window.contract.methods.cost().call();
  return Number(formatEther(cost))
}

export const isPaused = async () => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const isPaused = await window.contract.methods.paused().call();
    return isPaused;
}

export const isWLMint = async () => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const isPaused = await window.contract.methods.whitelistMintEnabled().call();
   return isPaused;
}

export const totalSupply = async () => {
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const totalSupply = await window.contract.methods.totalSupply().call();
    return totalSupply;
}


//whitelistMintEnabled
