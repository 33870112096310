const Privacy = () => {
  return (
    <>
      <p>
        <br />
      </p>
      <p>PRIVACY POLICY</p>
      <p>
        <br />
      </p>
      <h2>UPDATED – JUNE 2022</h2>
      <p>
        <br />
      </p>
      <p>
        Thank you for visiting https://degenmoonfrens.xyz (the “Site”). Your
        privacy is important to us. This Privacy Policy (the “Policy”) describes
        the types of information that Degen Moon Frens, LLC (“Degen Moon Frens”,
        “us”, “we”, or “our”) may collect from you or that you may provide when
        you visit the Site and the products, features, materials, and services
        we offer through the Site, our Discord channel, and our other social
        media platforms (collectively with the Site, the “Services”). This
        Policy also describes our policies and procedures for collecting, using,
        maintaining, protecting, and disclosing that information.
      </p>
      <p>
        This Policy applies to information we collect on the Site and through
        your use of the Services generally (including when you register for an
        account), and through communications between you and the Site (including
        email, text, Discord communications, and other electronic messages).
      </p>
      <p>
        This Policy does not apply to information collected by third parties,
        including any websites, services, and applications that you elect to
        access through the Services. It also does not apply to any information
        collected by the Degen Moon Frens offline or through any other
        means.&nbsp;
      </p>
      <p>
        Please review this Policy carefully. By accessing or using the Services
        (or by clicking on “accept” or “agree” to this Policy when prompted),
        you agree to the terms of this Policy on behalf of yourself or the
        entity or organization that you represent. If you do not agree to any
        term in this Policy, you should refrain from further use of our
        Services.
      </p>
      <h3>1.&nbsp;Children Using or Accessing the Services.</h3>
      <p>
        The Services and its content are not intended for, nor directed at,
        children under the age of eighteen (18). No one under the age of
        eighteen (18) may provide any personal information to or on the
        Services. Please note that we do not knowingly collect any personally
        identifiable information from children under the age of eighteen (18).
        If you are under the age of eighteen (18), please do not attempt to use
        or register for the Services or send any information about yourself to
        us, including your name, address, telephone number, or email address. If
        it is determined that we have inadvertently collected or received
        personally identifiable information from a child under the age of
        eighteen (18), we shall immediately take the necessary steps to ensure
        that such information is deleted from our system’s database. If you are
        a parent or legal guardian and think your child under the age of
        eighteen (18) has given us information, you can contact us at DegenFrens@gmail.com.
      </p>
      <h3>2.&nbsp;Changes to Our Privacy Policy.</h3>
      <p>
        This Policy was last revised on the date noted at the top of this page.
        We may update this Policy from time to time. If we make material
        changes, we will post the updated Policy on this page and notify you of
        such changes by means of (at our option) sending an email to the email
        address specified in your account, sending a message on the Services, or
        through a notice on the Site home page. Your continued use of the
        Services after we make changes is deemed to be acceptance of those
        changes, so please check the Policy periodically for updates.
      </p>
      <h3>3.&nbsp;Information We Collect.</h3>
      <p>
        We receive several types of information about you from various sources,
        including (A) information and content that you give us; (B)
        automatically collected information; and (C) demographic information or
        other aggregate&nbsp;information; and (D) information from other
        sources. Each is described in further details below.
      </p>
      <p>
        A.&nbsp;&nbsp; &nbsp;&nbsp;Information and&nbsp;Content&nbsp;You Give
        Us.
      </p>
      <p>
        We collect personal information that you knowingly choose to disclose.
        This may include:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;Personal
        Information.&nbsp;Personal information, such as your name, address,
        cryptocurrency address, Discord or social media usernames, e-mail
        address, username, password, and any other information you directly
        provide us on or through the Services. This includes information you
        provide when you register or create an account or make a request for
        customer service.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ii)&nbsp;&nbsp;
        &nbsp;&nbsp;Correspondences.&nbsp; Records and copies of your messages
        together with your email address, phone number, or username and our
        responses, if you choose to correspond with us through email or other
        electronic means.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iii)&nbsp;&nbsp; &nbsp;&nbsp;User
        Content.&nbsp;Information or content you submit to be published or
        displayed on public areas of the Services or transmitted to other users
        of the Services or third parties (collectively, “User Content”). Your
        User Content is posted on and transmitted to others at your own risk.
        Degen Moon Frens cannot control the actions of other users of the
        Services with whom you may choose to share your User Content. Therefore,
        we cannot and do not guarantee that your User Content will not be viewed
        by unauthorized persons.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iv)&nbsp;&nbsp; &nbsp;&nbsp;Transaction
        Information.&nbsp;Information about any purchase or transactions made on
        the Services. This includes payment information, such as your credit or
        debit card number and other card information; other account and
        authentication information; cryptocurrency transactions and wallet
        addresses; and billing, shipping, and contact details. We may collect
        information about any third-party service to conduct a payment or
        transaction, and that third party might additionally collect certain
        information.
      </p>
      <p>B.&nbsp;&nbsp; &nbsp;&nbsp;Information We Collect Automatically.</p>
      <p>
        We may use a variety of technologies to collect certain information
        about your equipment, browsing actions, and patterns whenever you
        interact with the Services, including:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;Activity
        Information.&nbsp;Details of your visits to our Services, including the
        types of content you&nbsp;view or engage with; the features you use; the
        actions you take; the people or accounts you interact with; the time,
        frequency, and duration of your activities; and other information about
        your use of and actions on the Services.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ii)&nbsp;&nbsp; &nbsp;&nbsp;Equipment
        Information.&nbsp;Information about your computer and internet
        connection, including your computer operating system, IP address,
        browser type, and browser language.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iii)&nbsp;&nbsp; &nbsp;&nbsp;Location Information.
        Information about the location of your device, including&nbsp;GPS
        location, for purposes of enhancing or facilitating the Services. For
        example, such information may be used to enable the functionality or
        features of the Services that provide you with geographically relevant
        information or&nbsp;enable you to remotely order&nbsp;and pay for the
        Services. We may also use information about the location of the device
        you are using to help us understand how the Services and functionality
        are being used and to deliver more relevant information or advertising.
      </p>
      <p>
        The gathering of this information may be used for maintaining the
        quality of the Services we provide, as well as providing overall general
        statistics related to the use of the Services. The technologies we use
        for this automatic data collection may include:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;Cookies. A cookie is a
        small data file stored on the hard drive of your computer either for
        only the duration of your visit on a website (“session cookies”) or for
        a fixed period (“persistent cookies”). Cookies contain information that
        can later be read by a web server. We may use cookies to provide you
        with a more personal and interactive experience on the Services.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ii)&nbsp;&nbsp; &nbsp;&nbsp;Web Beacons. Web beacons
        are small files that are embedded in webpages, applications, and emails
        (also known as “clear gifs”, “pixel tags”, “web bugs”, and “single-pixel
        gifs”). Web beacons allow, for example, for the tracking of those who
        has visited those webpages or opened an email, to test the effectiveness
        of marketing, and for other related website statistics.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iii)&nbsp;&nbsp;
        &nbsp;&nbsp;JavaScripts.&nbsp;JavaScripts&nbsp;are code snippets
        embedded in various parts of websites and applications that facilitate a
        variety of operations including accelerating the refresh speed of
        certain functionality or monitoring usage of various online components.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iv)&nbsp;&nbsp; &nbsp;&nbsp;Entity Tags.&nbsp;Entity
        Tags are HTTP code mechanisms that allow portions of websites to be
        stored or “cached” within your browser and validates these caches when
        the website is opened, accelerating website performance since the web
        server does not need to send a full response if the content has not
        changed.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(v)&nbsp;&nbsp; &nbsp;&nbsp;HTML5 Local
        Storage.&nbsp;HTML5 local storage allows data from websites to be stored
        or “cached” within your browser to store and retrieve data in HTML5
        pages when the website is revisited.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(vi)&nbsp;&nbsp; &nbsp;&nbsp;Resettable Device
        Identifiers. Resettable device identifiers (also known as “advertising
        identifiers”) are similar to cookies and are found on many mobile
        devices and tablets (for example, the “Identifier for Advertisers” or
        “IDFA” on Apple iOS devices and the “Google Advertising ID” on Android
        devices), and certain streaming media devices. Like cookies, resettable
        device identifiers are used to make online advertising more relevant.
      </p>
      <p>
        This Policy does not cover the use of tracking technologies by third
        parties. The Services may contain links, content, advertising, or
        references to other websites by third parties, including advertisers, ad
        networks and servers, content providers, and application providers.
        These third parties may use cookies or other tracking technologies to
        collect information about you when you use the Services. The information
        they collect may be associated with your personal information or they
        may collect information about your online activities over time and
        across different websites. Please be aware that we do not control these
        third parties' tracking technologies or when and how they may be used.
        Therefore, Degen Moon Frens does not claim nor accept responsibility for
        any privacy policies, practices, or procedures of any third party. We
        encourage you to read the privacy statements and terms and conditions of
        linked or referenced websites you enter. If you have any questions about
        an ad or other targeted content, you should contact the responsible
        provider directly.&nbsp;
      </p>
      <p>C. &nbsp;&nbsp; &nbsp;&nbsp;Demographic Information.</p>
      <p>
        We may collect demographic, statistical, or other aggregate information
        that is about you, but individually does not identify you. Some of this
        information may be derived from personal information, but it is not
        personal information and cannot be tied back to you.&nbsp;
      </p>
      <p>D. &nbsp;&nbsp; &nbsp;&nbsp;Information from Other Sources.</p>
      <p>
        We may receive information about you from other sources and add it to
        our account information. &nbsp;We protect this information according to
        the practices described in this Policy, plus any additional restrictions
        imposed by the source of the data. These sources may include online and
        offline data providers, from which we obtain demographic,
        interest-based, and online advertising related data; publicly available
        sources such as open government databases or social networks; and
        service providers who provide us with information, or updates to that
        information, based on their relationship with you. By gathering
        additional information about you, we can correct inaccurate information,
        enhance the security of your transactions, and give you product or
        service recommendations and special offers that are more likely to
        interest you.
      </p>
      <h3>4.&nbsp;How We Use Your Information.</h3>
      <p>
        We may use the information we collect about you in a variety of ways,
        including to:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;provide the Services and
        its content to you;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ii)&nbsp;&nbsp; &nbsp;&nbsp;respond to comments and
        questions, and provide customer service;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iii)&nbsp;&nbsp; &nbsp;&nbsp;fulfill any other
        purpose for which you provide such information;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(iv)&nbsp;&nbsp; &nbsp;&nbsp;communicate with you
        about your transaction, order, purchase, account, or subscription;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(v)&nbsp;&nbsp; &nbsp;&nbsp;inform you about
        important changes to, or other news about, the Services or any of
        its&nbsp;features or content;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(vi)&nbsp;&nbsp; &nbsp;&nbsp;operate, maintain,
        improve, personalize, and analyze the Services;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(vii)&nbsp;&nbsp; &nbsp;&nbsp;monitor and analyze
        trends, usage, and activities for marketing or advertising purposes;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(viii)&nbsp;&nbsp; &nbsp;&nbsp;detect, prevent, or
        investigate security breaches, fraud, and other unauthorized, prohibited
        or illegal activity;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ix)&nbsp;&nbsp; &nbsp;&nbsp;carry out our
        obligations and enforce our rights arising from any&nbsp;contracts
        entered into between you and us, including for billing and collection;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(x)&nbsp;&nbsp; &nbsp;&nbsp;maintain appropriate
        records for internal administrative purposes;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(xi)&nbsp;&nbsp; &nbsp;&nbsp;allow you to participate
        in interactive features on the Services;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(xii)&nbsp;&nbsp; &nbsp;&nbsp;send promotional
        communications, such as information about features, newsletters, offers,
        promotions, contests, and events;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(xiii)&nbsp;&nbsp; &nbsp;&nbsp;share information
        across products and devices to provide a more tailored and consistent
        experience on products you use; and
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(xiv)&nbsp;&nbsp; &nbsp;&nbsp;develop, test and
        improve new products or services, including by conducting surveys and
        research, and testing and troubleshooting new products and
        features.&nbsp;
      </p>
      <h3>5.&nbsp;How We Share Your Information.</h3>
      <p>
        We may disclose aggregated or anonymized information about our users
        without any restrictions. We will not share your personal information
        that we collect or you provide as described in this Policy except in the
        following circumstances:&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(A)&nbsp;&nbsp; &nbsp;&nbsp;With subsidiaries and
        affiliates for business purposes.&nbsp;To our subsidiaries and
        affiliated companies for purposes of management and analysis,
        decision-making, and other business purposes.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(B)&nbsp;&nbsp; &nbsp;&nbsp;When we work with service
        providers.&nbsp;To service providers, contractors, and other third
        parties that provide us with support services, such as development or
        maintenance of the Service, payment processing, website hosting, email
        and postal delivery, location mapping, product and service delivery, or
        analytics services, and who are bound by contractual obligations to keep
        personal information confidential and use it only for the purposes for
        which we disclose it to them.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(C)&nbsp;&nbsp; &nbsp;&nbsp;When we become involved
        in a sale or transfer of &nbsp;Degen Moon Frens. If we become involved
        with a merger, divestiture, restructuring, reorganization, dissolution,
        or other sale or transfer of some or all of Degen Moon Frens' assets
        (whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding), to business entities or people involved in the
        negotiation or transfer.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(D)&nbsp;&nbsp; &nbsp;&nbsp;When we are required by
        law.&nbsp;To comply with any court order, law, or legal
        process,&nbsp;including to respond to any government or regulatory
        request.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(E)&nbsp;&nbsp; &nbsp;&nbsp;When we enforce our
        rights.&nbsp;To enforce or apply this Policy, our Terms of Service
        (https://degenmoonfrens.xyz/terms_of_service), and other agreements, including for billing and collection
        purposes.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(F)&nbsp;&nbsp; &nbsp;&nbsp;To help
        protect&nbsp;lawful interests.&nbsp;If we believe disclosure will help
        us protect the rights, property, or safety of Degen Moon Frens, our
        users, partners, agents, and others. This includes exchanging
        information with other companies and organizations for fraud protection,
        and&nbsp;spam and malware prevention.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(G)&nbsp;&nbsp; &nbsp;&nbsp;To fulfill the purpose
        for that information or with your consent.&nbsp;To fulfill the purpose
        for which you provide the information, for any other purpose disclosed
        by us when you provide the information, or with your consent.
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(H)&nbsp;&nbsp; &nbsp;&nbsp;When we work with
        marketing service providers.&nbsp;To marketing service providers to
        assess, develop, and provide you with promotions and special offers that
        may interest you, administer contests, sweepstakes, and events, or for
        other promotional purposes.
      </p>
      <p>
        Information that you post on or through the public areas of the Services
        (e.g., chat rooms, bulletin boards, and discussion groups) are generally
        accessible to, and may be collected and used by, others and may
        result&nbsp;in unsolicited messages or other contact from others. Users
        of the Services are encouraged to exercise caution when providing
        personal information about themselves in public or interactive areas.
      </p>
      <h3>6.&nbsp;Your Choices.</h3>
      <p>
        A.&nbsp;&nbsp; &nbsp;&nbsp;Mechanisms to Control Your Information.&nbsp;
      </p>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information: &nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;Cookies &amp; Other
        Tracking Technologies. You may be able to set your browser to reject
        cookies and certain other technologies by adjusting the appropriate
        settings in your browser. Each browser is different, but many common
        browsers have preferences that may be adjusted to allow you to either
        accept or reject cookies and certain other technologies before they are
        set or installed or allow you to remove or reject the use or
        installation of certain technologies altogether. We recommend that you
        refer to the Help menu in your browser to learn how to modify your
        browser settings.&nbsp;
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(ii)&nbsp;Promotional Communications from Degen Moon
        Frens. If you do not wish to have your contact information used by Degen
        Moon Frens to promote our own or third-party products or services, you
        can opt-out by (i) informing us of your preference at the time you sign
        up for our correspondence, or complete any other form on or through the
        Services which we collect your data; (ii) modifying your user
        preferences in your account profile by checking or unchecking the
        relevant boxes; (iii) following the opt-out instructions in the
        promotional emails we send you; or (iv) sending us an email stating your
        request. Please note that if you opt out of receiving promotional
        communications from us, we may still send you transactional
        communications, including emails about your account or purchases.&nbsp;
      </p>
      <p>
        B.&nbsp;&nbsp; &nbsp;&nbsp;How We Respond to Do Not Track Signals.&nbsp;
      </p>
      <p>
        “Do Not Track” is a privacy preference you can set in most&nbsp;web
        browsers. When you turn on this preference, it sends a signal or message
        to the websites you visit indicating that you do not wish to be tracked.
        Please note that we currently do not respond to Do Not Track browser
        settings.
      </p>
      <h3>7.&nbsp;Accessing and Correcting Your Information.</h3>
      <p>
        You may send us an email to request access to, correct, or remove any
        personal information that you have provided to us. You may also access,
        correct, or remove your personal information by logging into the Site
        and visiting your account&nbsp;profile page. We may not be able to
        delete your personal information except by also deleting your account.
      </p>
      <p>
        We may not accommodate a request to change information if we believe the
        change would violate any law or legal requirement or cause the
        information to be incorrect.&nbsp;
      </p>
      <p>
        If you delete your User Content from the Services or your account,
        copies of your User Content may remain viewable in cached and
        archived&nbsp;pages or&nbsp;might have been copied or stored by other
        users of the Services.
      </p>
      <p>
        Funding and transaction information related to your use of certain
        services may be recorded on a public blockchain. Public blockchains are
        distributed ledgers, intended to immutably record transactions across
        wide networks of computer systems. Many blockchains are open to forensic
        analysis which can lead to deanonymization and the unintentional
        revelation of private financial information, especially when block chain
        data is combined with other data.&nbsp;
      </p>
      <p>
        You accept and acknowledge that by accessing our Site and Services, you
        may make your personal accessible on a public blockchain.&nbsp;
      </p>
      <p>
        Because blockchains are decentralized or third-party networks that are
        not controlled or operated by us, we are not able to erase, modify, or
        alter your personal information from such networks.
      </p>
      <h3>8. Legal Bases for Processing Your Information and Your Rights</h3>
      <ol>
        <li>
          <p>
            We may process personal information under the following conditions:
          </p>
        </li>
        <li>
          <p>
            Consent: You have given your consent for processing personal
            information for one or more specific purposes.
          </p>
        </li>
        <li>
          <p>
            Performance of a contract: Provision of personal information is
            necessary for the performance of an agreement with you and/or for
            any pre-contractual obligations thereof.
          </p>
        </li>
        <li>
          <p>
            Legal obligations: Processing personal information is necessary for
            compliance with a legal obligation to which Degen Moon Frens is
            subject.
          </p>
        </li>
        <li>
          <p>
            Vital interests: Processing personal information is necessary in
            order to protect your vital interests or of another natural person.
          </p>
        </li>
        <li>
          <p>
            Public interests: Processing personal information is related to a
            task that is carried out in the public interest or in the exercise
            of official authority vested in Degen Moon Frens.
          </p>
        </li>
        <li>
          <p>
            Legitimate interests: Processing personal information is necessary
            for the purposes of the legitimate interests pursued by Degen Moon
            Frens.
          </p>
        </li>
      </ol>
      <p>
        In any case, Degen Moon Frens will gladly answer any related questions
        you may have.
      </p>
      <ol start={2}>
        <li>
          <p>
            Degen Moon Frens undertakes to respect the confidentiality of your
            personal information and to guarantee you can exercise your rights.
            You have the right under this Privacy Policy, and by law depending
            on your location, to:
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li>
          <p>
            Request access to your personal information. The right to access,
            update or delete the information we have on you. Whenever made
            possible, you can access, update or request deletion of your
            personal information directly within your account settings section.
            If you are unable to perform these actions yourself, please contact
            us to assist you. This also enables you to receive a copy of the
            personal information we hold about you.
          </p>
        </li>
        <li>
          <p>
            Request correction of the personal information that we hold about
            you. You have the right to have any incomplete or inaccurate
            information we hold about you corrected.
          </p>
        </li>
        <li>
          <p>
            Object to processing of your personal information. This right exists
            where we are relying on a legitimate interest as the legal basis for
            our processing and there is something about your particular
            situation, which makes you want to object to our processing of your
            personal information on this ground. You also have the right to
            object where we are processing your personal information for direct
            marketing purposes.
          </p>
        </li>
        <li>
          <p>
            Request erasure of your personal information. You have the right to
            ask us to delete or remove your personal information when there is
            no good reason for us to continue processing it.
          </p>
        </li>
        <li>
          <p>
            Request the transfer of your personal information. We will provide
            to you, or to a third-party you have chosen, your personal
            information in a structured, commonly used, machine-readable format.
            Please note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </p>
        </li>
        <li>
          <p>
            Withdraw your consent. You have the right to withdraw your consent
            on using your personal information. If you withdraw your consent, we
            may not be able to provide you with access to certain specific
            functionalities of the Service.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li>
          <p>
            You may exercise your rights of access, rectification, cancellation
            and opposition by contacting us. Please note that we may ask you to
            verify your identity before responding to such requests. If you make
            a request, we will try our best to respond to you as soon as
            possible.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Notwithstanding the foregoing, because block chains are
            decentralized or third-party networks that are not controlled or
            operated by us, we are not able to erase, modify, or alter your
            personal information from such networks.
          </p>
        </li>
      </ol>
      <h3>9. California Privacy Rights.</h3>
      <p>
        If you are a California resident, California law may provide you with
        additional rights regarding our use of your personal information. To
        learn more about your California privacy rights, please submit a written
        request to DegenFrens@gmail.com.
      </p>
      <h3>10.&nbsp;How We Protect Your Information.</h3>
      <p>
        We take reasonable precautions to secure your personal information. We
        have implemented technical, physical, and administrative security
        measures designed to reduce the risk of loss, misuse, unauthorized
        access, disclosure, or modification of your information. We have put in
        place appropriate physical, electronic, and managerial procedures to
        safeguard the information we collect. All information you provide to us
        is stored on our secure servers behind firewalls. When you transmit
        highly sensitive information (such as a credit card number) through the
        Services, we encrypt the transmission of that information using Secure
        Sockets Layer (SSL) technology.&nbsp;
      </p>
      <p>
        The safety and security of your information also depend on you. Where we
        have given you (or where you have chosen) a password for access to
        certain parts of the Services, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone.&nbsp;
      </p>
      <p>
        For conducting cryptocurrency transactions, we use third-party
        electronic wallet extensions such as MetaMask or Coinbase Wallet; your
        interactions with MetaMask or Coinbase Wallet and/or any third-party
        electronic wallet extensions are governed by the applicable privacy
        policies.&nbsp;
      </p>
      <p>
        While we have employed security technologies and procedures to assist
        safeguarding your personal information, no system or network can be
        guaranteed to be 100% secure. Unauthorized entry or use, hardware or
        software failure, and other factors may compromise the security of user
        information at any time. Any transmission of personal information is at
        your own risk. We are not responsible for circumvention of any privacy
        settings or security measures contained on the Site or any other
        Services.
      </p>
      <h3>11.&nbsp;Terms of Service.</h3>
      <p>
        If you choose to visit the Services, your visit and any dispute over
        privacy is subject to this Policy and our Terms of Service (http://degenmoonfrens.xyz/terms_of_service),
        including limitations on damages, resolution of disputes, and
        application of the law of the state of Wyoming.
      </p>
      <p>
        <br />
      </p>
    </>
  );
};

export default Privacy;