import footer_bg from "../../assets/images/footer_bg.png";
import moon from "../../assets/images/moon_dark.png";
import SocialLinks from "./SocialLinks";
import { SubHeading } from "./typography";

const Footer = (props) => {
  return (
    <footer className="bg-[#272D6F] -mt-1 relative">
      <img
        src={footer_bg}
        alt="footer background"
        className="bg-bottom min-h-[308px] w-full"
      />
      <div className=" bottom-2 lg:bottom-10 absolute flex flex-col w-full  items-center gap-2 lg:gap-10">
        <img
          src={moon}
          alt="moon footer"
          className="w-[66px] h-[58px] lg:w-[178px] lg:h-[152px]"
        />
        <SocialLinks small />
        <SubHeading>&copy; Copyright 2022 Degen Moon Frens</SubHeading>
      </div>
    </footer>
  );
};

export default Footer;
