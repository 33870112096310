import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserContextProvider";
import { useParams } from "react-router-dom";
import Modal from "react-modal";

import "../../styles/app.css";
import FireBaseUserService from "../../firebaseUserService";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },
};

const InviteConnectButton = () => {
  const { userAddress, connectWalletHandler } = useContext(UserContext);
  const [validInvite, setValidInvite] = useState(null);
  const { address } = useParams();
  const [clicked, setClicked] = useState(false);
  const firebaseUserService = new FireBaseUserService();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#e5e5e5";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const checkProvidedAddress = async () => {
    if (await checkMaxCount()) return;
    let inviter = await firebaseUserService.getUser(address);
    let invitee = await firebaseUserService.getUser(userAddress);
    if (inviter && !invitee) {
      if (inviter.count > 0 && address !== userAddress) {
        await firebaseUserService.addUser(userAddress);
        await firebaseUserService.updateInviter(address, inviter, userAddress);
        setValidInvite(true);
        openModal();
        return;
      } else {
        setValidInvite(false);
      }
    } else if (inviter && invitee) {
      setValidInvite(true);
      openModal();
    } else {
      console.log("The Inviter or Invitee is invalid or already present.....");
      setValidInvite(false);
      openModal();
    }
  };

  const checkMaxCount = async () => {
    let res = await firebaseUserService.isCountMax();
    if (res) {
      setValidInvite(!res);
      return !res;
    }
    return res;
  };

  const copyText = (e) => {
    e.preventDefault();
    setClicked(true)
    navigator.clipboard.writeText(`https://degenmoonfrens.xyz/invite/${userAddress}`);
  }

  useEffect(() => {
    checkProvidedAddress();
  }, [userAddress]);

  if (typeof validInvite === "undefined" || validInvite === null) {
    return (
      <button
        className="top_right_section_buttons forth_font_card_header"
        onClick={connectWalletHandler}
      >
        <h2 className="forth_font_card_header">Claim WL</h2>
      </button>
    );
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName="Overlay2"
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col justify-center text-center rounded p-3 gap-4 w-[300px] h-[400px] cursor-pointer  break-all forth_font_card_header bg-slate-300">
          <h2 className="text-black">
            {validInvite
              ? "Share this invite link with up to 2 frens so that they can sign up for the WL as well!"
              : "Sorry Degen, Looks like you're not on the list or the invite was invalid."}
          </h2>
          {validInvite && (
            <>
              <div
                className={`cursor-pointer ${clicked ?  "text-green ": "text-black"}`}
                onClick={copyText}
              >
                <p className="" id="link">{`https://degenmoonfrens.xyz/invite/${userAddress}`}</p>
              </div>

              <a
                className="top_right_section_buttons forth_font_card_header"
                href={`https://www.premint.xyz/${address == "discord_ogs" ? "degenmoonfrens-community-list" : "degenmoonfrens-fren"}/`}
                target="_blank"
                onClick={closeModal}
                rel="noreferrer"
              >
                <p className="forth_font_card_header">Register</p>
              </a>
            </>
          )}
          <button className="top_right_section_buttons forth_font_card_header" onClick={closeModal}>
            <p className="forth_font_card_header">CLOSE</p>
          </button>
        </div>
      </Modal>

      <button className="top_right_section_buttons forth_font_card_header" onClick={openModal}>
      <p className="forth_font_card_header">{validInvite ? "SUCCESS" : "FAILED"}</p>  
      </button>
    </>
  );
};

export default InviteConnectButton;
