import hero from "../../assets/images/Untitled_Artwork.png";
import moon_brand from "../../assets/images/moon_brand.png";
import rocket from "../../assets/images/rocket.png";
import ButtonPrimary from "./Button/ButtonPrimary";
import curve3 from "../../assets/images/curve3.png";
import { LargeHeading, SubHeading } from "./typography";
import InviteConnectButton from "./InviteConnectButton";
import { useEffect } from "react";
import FireBaseUserService from "../../firebaseUserService";
import { mainMintDecision } from "../../helpers/utils";


const Hero = (props) => {
  const { invite } = props;

  return (
    <div id="home" className=" bg-primary-dark  relative ">
      <img
        src={curve3}
        alt=""
        className="absolute bottom-0 translate-y-[65%]  w-full"
      />
      <div className="text-white flex flex-col lg:flex-row items-center translate-x-0 lg:justify-around p-4 pb-24 lg:pb-0">
        <img
          className="h-[400px] w-[400px] lg:h-[600px] lg:w-[600px]  object-contain"
          src={hero}
          alt="Degenfren"
        />
        <div className="space-y-4 text-center">
          <LargeHeading>Degen Moon Frens</LargeHeading>
          <SubHeading className=" font-c4th text-xl md:text-4xl max-w-2xl font-light">
            Community. Education. Alpha.
          </SubHeading>
          {invite ? (
            <InviteConnectButton />
          ) : (
            <button
                className="top_right_section_buttons forth_font_card_header"
                onClick={mainMintDecision}
              >
                <p className="forth_font_card_header">Go to Mint</p>
              </button>
            
          )}
        </div>
      </div>
      <img
        src={rocket}
        alt="moon brand"
        className="hidden lg:inline-block absolute  top-24 left-[46%] hover:-rotate-12 transition "
      />
      <img
        src={moon_brand}
        alt="moon brand"
        className=" absolute h-40 w-40 -bottom-10 2xl:bottom-0 right-0 lg:right-10  hover:-rotate-12 transition "
      />
    </div>
  );
};

export default Hero;
