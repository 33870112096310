
import DegenList from "../components/team/DegenList";
import FAQList from "../components/faq/FAQList";
import Hero from "../components/shared/Hero";
import About from "../components/shared/About";
import RoadMap from "../components/shared/RoadMap";

const Invite = () => {
  return (
    <div>
      <Hero invite />
      <About />
      <RoadMap />
      <DegenList />
      <FAQList />
    </div>
  );
};

export default Invite;
