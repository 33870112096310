import { createContext, useState, useLayoutEffect, useCallback } from "react";

export const DrawerContext = createContext({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleResize = useCallback(() => {
    if (window.innerWidth > 1024) {
      setIsOpen(false);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        onClose,
        onOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
