const MenuIcon = (props) => {
  return (
    <svg width="37.5" height="26" viewBox="0 0 37.5 26" {...props}>
      <g transform="translate(1.5 1.5)">
        <path
          d="M4.5,18H39"
          transform="translate(-4.5 -6.5)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M4.5,9H39"
          transform="translate(-4.5 -9)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M4.5,27H39"
          transform="translate(-4.5 -4)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
