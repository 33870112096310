const TOS = () => {
  return (
    <>
      <p>
        <br />
      </p>
      <p>TERMS OF SERVICE</p>
      <p>
        <br />
      </p>
      <p>Updated: June 2022&nbsp;</p>
      <p>
        <br />
      </p>
      <p>1.&nbsp;&nbsp; &nbsp;&nbsp;Project Overview&nbsp;</p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;The following terms and conditions constitute a
        legally binding agreement (together with any terms and conditions
        incorporated herein by reference, this “Agreement” or the “Terms”)
        between you (referred to herein as “you”, “your”, or “user”) and, Degen
        Moon Frens, LLC as the sponsor of the Degen Moon Frens NFT Project
        (“Degen Moon Frens”, “we”, “us”, or “our”), governing your interaction
        with the Degen Moon Frens NFT (as defined below) collection. By entering
        into this Agreement, participating in the minting, purchasing, or sale
        of a Degen Moon Frens NFT (either directly from us or on resale), using
        our Site, participating in the Degen Moon Frens Discord server, and/or
        otherwise participating in the Project (as such term is defined below),
        you expressly acknowledge that you understand this Agreement and accept
        all of its terms. If you do not agree to the terms set forth in this
        Agreement, you must not use our Site or participate in the
        Project.&nbsp;
      </p>
      <p>
        <br />
        &nbsp; &nbsp;&nbsp;BY ACCESSING OR USING ANY PART OF THE SITE OR
        ENGAGING WITH THE PROJECT, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND
        AGREED TO BE BOUND BY THESE TERMS, WHICH CONTAIN A TERMS OF SALE
        AGREEMENT, AN ARBITRATION AGREEMENT, A WAIVER OF CLASS-ACTION RIGHTS,
        AND LIABILITY LIMITATIONS.
      </p>
      <p>
        <br />
      </p>
      <p>
        PLEASE READ SECTION 14 OF THIS AGREEMENT CAREFULLY, AS IT CONTAINS AN
        ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE
        DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION
        UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT. YOU WILL ONLY BE
        PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
        BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
        ACTION OR PROCEEDING; AND YOU ARE AGREEING TO MANDATORY INDIVIDUAL
        ARBITRATION FOR THE RESOLUTION OF DISPUTES AND WAIVING YOUR RIGHT TO A
        JURY TRIAL ON YOUR CLAIMS.&nbsp;
        <br />
        <br />
        &nbsp; &nbsp;&nbsp;The Degen Moon Frens NFT Project consists of the
        various collection of Degen Moon Frens NFTs (each, a “Degen Moon Frens
        NFT”), running on the Ethereum network, named on the Site (now or in the
        future). &nbsp;&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;If you have any questions about these Terms, please
        contact us by email at&nbsp;DegenFrens@gmail.com. &nbsp;If you access
        any Degen Moon Frens Site on a Social Media Platform (e.g., Facebook,
        Twitter, Discord, or Instagram), you also consent to and agree to abide
        by the terms and conditions of that social media platform.
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>2. &nbsp;&nbsp; &nbsp;&nbsp;Our NFT Drop.</p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp;&nbsp;(a)&nbsp; &nbsp;&nbsp;The Degen Moon Frens NFTs are
        minted through a smart contract on the Ethereum Blockchain. A blockchain
        is a distributed digital ledger of transactions maintained by a
        distributed peer-to-peer computer network that cryptographically
        validates transactions and records such transactions on the ledger.
        &nbsp;The Degen Moon Frens NFTs are recorded on the Ethereum Blockchain,
        and we use smart contracts to allow you to buy, claim, sell, send and
        receive Degen Moon Frens NFTs. &nbsp;You acknowledge and agree that
        certain information, including your cryptocurrency wallet address and
        the transactions you conduct through that cryptocurrency wallet address,
        may be publicly available and viewable on the Ethereum Blockchain.
        &nbsp;Your Degen Moon Frens NFTs can be transferred on the Ethereum
        Blockchain (or another blockchain as technology permits) from one
        cryptocurrency wallet address to another cryptocurrency wallet address.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(b)&nbsp; &nbsp;&nbsp;At selected times determined by
        us, we will make Degen Moon Frens NFTs available for purchase or claim
        through our Site. &nbsp;The price for each Degen Moon Frens NFT will be
        designated in ETH, in our sole and absolute discretion. We cannot, and
        expressly do not, guarantee that Degen Moon Frens NFTs will be available
        for purchase at the time you seek to purchase one. &nbsp;We expressly
        reserve the right to modify the types, prices and number of Degen Moon
        Frens NFTs available at our sole and absolute discretion at any time and
        from time to time.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(c)&nbsp; &nbsp;&nbsp;Degen Moon Frens NFTs are
        minted directly into the cryptocurrency wallet you have connected to our
        Site and through which you made your payment in ETH. &nbsp;We never hold
        custody nor take ownership or possession of your Degen Moon Frens NFT.
        &nbsp;You acknowledge and agree that if you decide to purchase a Degen
        Moon Frens NFT outside of our Site, such purchases will be entirely at
        your sole risk. &nbsp;&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(d)&nbsp; &nbsp;&nbsp;You are responsible for
        ensuring your cryptocurrency wallet address has a sufficient amount of
        the ETH to cover your purchase as well as any applicable Gas Fees.
        &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(e)&nbsp; &nbsp;&nbsp;You are permitted to sell,
        trade, or distribute your Degen Moon Frens NFT on any smart contract
        enabled secondary marketplaces, platforms and exchanges operated by
        third parties where users can sell, purchase, transfer, list for auction
        and bid on NFTs (“Secondary Marketplaces”). &nbsp;Note that Degen Moon
        Frens NFTs may not be compatible with all Secondary Marketplaces, and we
        make no guarantee about the availability or functionality of any
        Secondary Marketplace.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(f)&nbsp; &nbsp;&nbsp;This Agreement only relates to
        your use of the Site, and does not relate to any other website or
        Internet-based services, including, but not limited to, Secondary
        Marketplaces or other websites or browser extensions to which the Site
        may link (collectively, “Third-Party Sites”). &nbsp;References or links
        to any Third-Party Site are provided for your convenience and
        information only, for example, to make it convenient for you to trade
        your Degen Moon Frens NFT. &nbsp;Such links should not be interpreted as
        endorsements by us of any Third-Party Site. When you click such link, we
        may not warn you that you have left our Site and are subject to the
        terms and conditions and privacy policies of a Third-Party Site. We are
        not responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with your use of
        or reliance on any content, advertising, products, services, information
        or other materials on Third-Party Sites. &nbsp; &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(g)&nbsp; &nbsp;&nbsp;You acknowledge and agree that
        we are not a party to any agreement or transaction where you trade on
        any Secondary Marketplace a Degen Moon Frens NFT which was initially
        purchased via our Site, whether or not a commission or fee is received
        by us as a consequence of the transaction. &nbsp;We are not liable for
        any loss incurred by you in connection with any transaction that takes
        place on Secondary Marketplaces or on any other third-party sites or
        services.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(h)&nbsp; &nbsp;&nbsp;We may provide experiences on
        Social Media Platforms that enable online sharing and collaboration
        among users who have registered to use them. &nbsp;Any content you post
        is subject to the terms of use and privacy policies of those platforms
        and related services. &nbsp;We have no control over such Social Media
        Platforms or related services.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp; &nbsp;&nbsp;Every transaction on the
        Ethereum Blockchain requires the payment of a transaction fee known as a
        “Gas Fee”. &nbsp;This means that you are required to pay a Gas Fee for
        each purchase of a Degen Moon Frens NFT via the Site. &nbsp;If you seek
        to transfer your Degen Moon Frens NFT via a secondary marketplace you
        may also incur Gas Fees or other transaction costs. &nbsp;Gas Fees are
        paid to the network of computers that operate the Ethereum blockchain
        and are not paid to Degen Moon Frens. &nbsp;Note that Gas Fees often
        fluctuate based on a number of factors that are entirely out of our
        control.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(j)&nbsp; &nbsp;&nbsp;You are solely responsible for
        determining and paying (or reimbursing for the payment of) any and all
        sales, use, value-added and other taxes, duties, and assessments
        (excluding taxes imposed on our net income) now or hereafter claimed or
        imposed by any tax or other Governmental Authority associated with your
        use of our Site (collectively, the “Taxes”). &nbsp;You will pay or
        reimburse us for all Taxes of any jurisdiction (whether national,
        federal, state, local, foreign or other), including value added taxes
        and taxes as required by international tax treaties, customs or other
        import or export taxes, and amounts levied in lieu thereof based on
        charges set, services performed or payments made hereunder, as are now
        or hereafter may be imposed under the authority of any national,
        federal, state, local, foreign or other taxing jurisdiction; and will
        not be entitled to deduct the amount of any such Taxes or amounts levied
        in lieu thereof from payments (including, without limitation, Gas Fees)
        made to us pursuant to this Agreement.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(k)&nbsp; &nbsp;&nbsp;If you sell your Degen Moon
        Frens NFT via a secondary marketplace, such transaction is subject to a
        transaction fee determined by us, in our sole discretion (a “Transaction
        Fee”) which Transaction Fee may be automatically deducted from the sale
        proceeds you receive for your Degen Moon Frens NFT. &nbsp;All
        Transaction Fees are applied to the final sale price of the Degen Moon
        Frens NFT and are collected and distributed to us at the time of sale
        through smart contracts on the Ethereum Blockchain.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(l)&nbsp; &nbsp;&nbsp;&nbsp;The Site may contain a
        description of anticipated future milestones for the Project, and there
        may be anticipated future milestones that have been noted via Social
        Media Platforms (the “Roadmap”). We will endeavor to implement the
        milestones of the Roadmap in accordance with the corresponding dates.
        However, the Roadmap is merely suggestive and there are no guarantees
        that all milestones in the Roadmap will be fulfilled, nor are there any
        guarantees that such milestones will be accomplished by the indicated
        dates. We may delay, defer, or choose not to implement certain
        milestones on the Roadmap, in our sole and absolute discretion and
        without any recourse to you. You recognize that the Project remains in
        “beta” and the blockchain industry and regulatory landscape is unsettled
        and constantly evolving and that certain milestones may become
        unfeasible or undesirable to implement.
      </p>
      <p>
        <br />
      </p>
      <p>
        (m)&nbsp;&nbsp; &nbsp;&nbsp;Degen Moon Frens NFTs are meant to be fun
        pieces of art for you to collect. When you purchase a Degen Moon Frens
        NFT, you agree that your purchase of the Degen Moon Frens NFT is all you
        are guaranteed to receive in exchange for your funds. Whether through
        primary or secondary channels, the art is what you receive. Degen Moon
        Frens NFTs are not meant as investment vehicles. We make absolutely no
        promise or guarantee that Degen Moon Frens NFTs will be worth anything
        more than what you and the market deem the art to be worth. This could
        very well be zero. You understand that Degen Moon Frens NFTs have no
        inherent monetary value, and they should be treated as nothing more than
        a collectible with potential future value or lack thereof. Any future
        benefits are ancillary to this purchase and not to be taken into
        consideration with your initial purchase. You agree that you are not
        relying on any future commitments by us.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>3.&nbsp;&nbsp; &nbsp;&nbsp;Content.&nbsp;</p>
      <p>
        <br />
        &nbsp; &nbsp;&nbsp;(a)&nbsp; &nbsp;&nbsp;Intellectual Property Rights.
        By using our Site and participating in the Project, you expressly
        acknowledge and agree that the Degen Moon Frens NFTs and all content,
        photographs, computer code, smart contract code, sound or videos, media,
        images, formulas, graphics, webinars, training materials, products,
        services and/or other information and materials, and selection and
        arrangements associated with the Project and/or located on our Site is
        copyrighted work under the United States and other copyright laws, and
        is the property of or licensed by Degen Moon Frens, LLC, made available
        on our Site or any information, materials, and content transferred via a
        downloadable file or link by us or other third parties (the “Content”).
        All trademarks, service marks, and trade names (collectively, the
        “Marks”) are trademarks or registered trademarks of and are proprietary
        to Degen Moon Frens, LLC or other respective owners that have granted
        Degen Moon Frens, LLC the right to use such Marks. We reserve all rights
        that are not specifically granted to users.&nbsp;&nbsp; &nbsp;&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        (b)&nbsp; &nbsp;&nbsp;No Refunds. All purchases of Degen Moon Frens
        NFTs, as well as any associated charges, are non-refundable. This
        no-refund policy shall apply at all times regardless of your decision to
        terminate usage of the Degen Moon Frens NFT, any disruption to the
        operations of any components of the Degen Moon Frens NFT, or any other
        reason whatsoever.
      </p>
      <p>
        <br />
      </p>
      <p>(c)&nbsp; &nbsp;&nbsp;License</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            Personal Use License: Subject to your continued compliance with
            these Terms, we grant you a worldwide, royalty-free, non-exclusive
            license (without the right to assign or sublicense) to use, copy,
            and display your Degen Moon Frens NFT, along with any extensions
            that you choose to create or use, solely for the following purposes:
            (i) for your own personal, non-commercial use; (ii) as part of a
            marketplace that permits the purchase and sale of your Degen Moon
            Frens NFT, provided that the marketplace cryptographically verifies
            each Degen Moon Frens NFT’s owner’s rights to display the art for
            their Degen Moon Frens NFT to ensure that only the actual owner can
            display the art; or (iii) as part of a third-party website or
            application that permits the inclusion, involvement, or
            participation of your Degen Moon Frens NFT, provided that the
            website/application cryptographically verifies each Degen Moon Frens
            NFT’s owner’s rights to display the art, and provided that the art
            is no longer visible once the owner of the Degen Moon Frens NFT
            leaves the site/application.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={2}>
        <li>
          <p>
            Commercial Use License: Subject to your continued compliance with
            these Terms, we grant you a worldwide, royalty-free, non-exclusive
            license (without the right to assign or sublicense) to use, copy,
            and display your Degen Moon Frens NFT for the purpose of creating
            derivative works based upon your Degen Moon Frens NFT (“Commercial
            Use”). Examples of such use include, but are not limited to, the use
            of your Degen Moon Frens NFT to produce and sell merchandise
            products (clothing, accessories, posters, or other artworks, etc.)
            that display copies of the art embodied in your specific Degen Moon
            Frens NFT, and to create any digital derivatives or other NFT
            representations of your Degen Moon Frens NFT, or to manufacture and
            sell printed copies of your Degen Moon Frens NFT. &nbsp;For the
            avoidance of doubt, for purposes of this Commercial Use, the Degen
            Moon Frens NFT refers to the artwork embodied in the Degen Moon
            Frens NFT, and individual components of the Degen Moon Frens NFT
            (for example but not limited to the colors, flourishes, fonts,
            images, logos, and any other individual or combined trait or
            elements) may not be used, modified, or displayed on a standalone
            basis.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        (e)&nbsp; &nbsp;&nbsp;The license granted to you hereunder shall
        automatically terminate and all rights shall return to Degen Moon Frens
        if: (i) at any time you sell, trade, donate, give away, transfer, burn,
        or otherwise dispose of your Degen Moon Frens NFT for any reason; (ii)
        you breach any of the Agreement and conditions; (iii) you have a
        trustee, receiver or similar party appointed for your property, become
        insolvent, acknowledge your insolvency in any manner, make an assignment
        for the benefit of your creditors, or file a petition of bankruptcy;
        (iv) you engage in any unlawful business practice related to the Degen
        Moon Frens NFT; (iv) you initiate any legal actions against the Degen
        Moon Frens, LLC and/or its members, officers, directors, affiliates,
        agents, attorneys and employees.
      </p>
      <p>
        <br />
      </p>
      <p>
        (f)&nbsp;&nbsp; &nbsp;&nbsp;Degen Moon Frens IP. Other than the rights
        to the Degen Moon Frens NFT, nothing herein gives you any rights to any
        other trademarks or other intellectual property rights belonging to
        Degen Moon Frens, LLC including, without limitation, the &nbsp;names and
        marks, and the associated logos. All of these rights are expressly
        reserved in the name of Degen Moon Frens, LLC. Nothing herein shall
        restrict our right to use, copy, and display any individual Degen Moon
        Frens NFT for our own uses. If you wish to discuss with us a license for
        use of the Degen Moon Frens IP, you may contact us at
        DegenFrens@gmail.com.
      </p>
      <p>
        <br />
      </p>
      <p>
        (g)&nbsp; &nbsp;&nbsp;Feedback. You may choose to submit comments, bug
        reports, ideas or other feedback about the Site or the Degen Moon Frens
        NFT project, including, without limitation, about how to improve the
        Site (collectively, “Feedback”). By submitting any Feedback, you agree
        that we are free to use such Feedback in any way we choose without
        additional compensation to you and you hereby grant us a perpetual,
        irrevocable, nonexclusive, worldwide license to incorporate and use the
        Feedback for any purpose. You shall not, under any circumstances or for
        any reason, modify, publish, transmit, participate in the transfer or
        sale of, reproduce, create derivative works of, distribute, publicly
        perform, publicly display, or in any way exploit any of the materials or
        Content in whole or in part, without our express prior written consent,
        which consent may be granted, withheld, conditioned and/or delayed in
        our sole and absolute discretion. &nbsp;Modification of the Content or
        use of the Content for any other purpose is a violation of the copyright
        and other proprietary rights of Degen Moon Frens, as well as other
        authors who created the materials, and may be subject to monetary
        damages and penalties.
      </p>
      <p>
        <br />
        &nbsp; &nbsp;&nbsp;(h)&nbsp; &nbsp;&nbsp;Third-Party Content. Our Site
        contains Content that we create and may also include Content provided by
        third parties. We do not monitor, we do not endorse, and we are not
        liable for any third-party content. There may be some inadvertent
        accuracies or errors in the content, and we do not guarantee the
        accuracy, integrity, completeness or quality of the content on our Site
        or located at third party URLs that may be posted on our Site. Degen
        Moon Frens is not responsible or the content on any linked site or any
        link contained in a linked site. We do not endorse or accept
        responsibility for the content of such third-party sites.
      </p>
      <p>
        <br />
        &nbsp; &nbsp;&nbsp;(i)&nbsp; &nbsp;&nbsp;Third-Party Services. Third
        parties may offer their services directly to you through the Site. In
        such case, you may be required to agree to the third party’s terms of
        service and/or privacy policy to use the service. Degen Moon Frens will
        not be liable in any way for the acts or omissions of such third party,
        the terms of service or privacy policy or its failure to adhere to its
        terms of services or privacy policy, or any loss, damages, liability or
        expenses (including attorneys’ fees) that you may incur arising from or
        related to such third party’s services or products
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(j)&nbsp; &nbsp;&nbsp;You agree not to circumvent,
        disable or otherwise interfere with security-related features of the
        Site that prevent or restrict use or copying of any Content or enforce
        limitations on use of the Content. &nbsp;By accessing our Site, you
        agree not to use any data mining, robots, scraping or similar data
        gathering or extraction methods.
      </p>
      <p>
        <br />
      </p>
      <p>
        4.&nbsp; &nbsp;&nbsp;Representations and Warranties. &nbsp;By
        participating in the Project, you expressly represent, warrant and
        acknowledge the following:
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(a)&nbsp; &nbsp;&nbsp;You are not a citizen or
        resident of a state, country, territory or other jurisdiction that is
        embargoed by the United States or where your use of the Site would be
        illegal or otherwise violate any applicable law. Specifically, you
        represent that you are not located in, organized in, or a resident of
        Cuba, Iran, Syria, North Korea, Russia, the Crimea region, Venezuela, or
        any other jurisdiction where Applicable Law prohibits you from accessing
        or using the Site; and you represent that you are not, and have never
        been, named on the Office of Foreign Asset Control of the U.S.
        Department of the Treasury’s Specially Designated and Blocked Persons
        List. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(b)&nbsp; &nbsp;&nbsp;You have the necessary
        technical expertise and ability to review and evaluate the security,
        integrity and operation of any transactions that you engage in on the
        Site. &nbsp;Further, you acknowledge that you have a sufficient
        understanding of the functionality, usage, storage, transmission
        mechanisms and other material characteristics of, blockchain technology,
        NFTs, digital assets, digital wallets and cryptocurrencies to understand
        this Agreement and to understand the risks and implications of
        purchasing a Degen Moon Frens NFT.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(c)&nbsp; &nbsp;&nbsp;You have the knowledge,
        experience, understanding, professional advice and information to make
        your own evaluation of the merits, risks and applicable compliance
        requirements under Applicable Law of acquiring an NFT, and assume all
        financial risks associated with acquiring NFTs and/or otherwise engaging
        in transactions on the Ethereum Blockchain. &nbsp;You know, understand
        and accept the risks associated with your Ethereum Address, the Ethereum
        Blockchain, ETH and NFTs.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(d)&nbsp; &nbsp;&nbsp;BY ACCESSING THE SITE, YOU
        EXPRESSLY REPRESENT AND WARRANT THAT YOU (i) HAVE READ AND UNDERSTAND
        THIS AGREEMENT, (ii) ARE AT LEAST 18 YEARS OF AGE AND OF LEGAL AGE TO
        ENTER INTO A BINDING AGREEMENT, AND (iii) THAT YOU ACCEPT THIS AGREEMENT
        AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.
      </p>
      <p>
        <br />
      </p>
      <p>
        5.&nbsp;&nbsp; &nbsp;&nbsp;Restrictions. &nbsp;&nbsp;You are solely
        responsible for your own conduct while accessing or using the Site and
        using our Discord, and for any consequences thereof. You agree to use
        the Site and our Discord only for purposes that are legal, proper and in
        accordance with this Agreement and any Applicable Laws or regulations.
        &nbsp;By way of example, and not as a limitation, you may not, and may
        not allow any third party acting on your behalf to: (i) send, upload,
        distribute or disseminate any unlawful, defamatory, harassing, abusive,
        fraudulent, hateful, violent, obscene, or otherwise objectionable
        content; (ii) distribute viruses, worms, defects, Trojan horses,
        corrupted files, hoaxes, or any other items of a destructive or
        deceptive nature; (iii) impersonate another person; (iv) upload, post,
        transmit or otherwise make available through the Site or Discord any
        content that infringes the intellectual property or proprietary rights
        of any party or otherwise violates the legal rights of others; (v)
        engage in, promote, or encourage illegal activity (including, without
        limitation, money laundering); (vi) interfere with other users’ use of
        the Site or Discord; (vii) use the Site or Discord for any unauthorized
        commercial purpose; (viii) modify, adapt, translate, or reverse engineer
        any portion of the Site or Discord; (ix) remove any copyright, trademark
        or other proprietary rights notices contained in or on the Site or
        Discord or any part of the Site and/or Discord; (x) use any technology
        to collect information about the Site or Discord for any unauthorized
        purpose; (xi) access or use the Site or Discord for the purpose of
        creating a product or service that is competitive with any of our
        products or services; (xii) use any Content in movies, videos or other
        forms of media, except to the limited extent that such use is expressly
        permitted by this Agreement or solely for your own personal,
        non-commercial use; (xiii) sell, distribute for commercial gain
        (including, without limitation, giving away in the hopes of eventual
        commercial gain), or otherwise commercialize merchandise that includes,
        contains, or consists of any Content; (xiv) attempt to trademark,
        copyright or otherwise acquire any intellectual property rights in or to
        any Content; and/or (xv) otherwise utilize any Content for your or any
        third party’s commercial benefit. &nbsp;If you engage in any of the
        activities prohibited by this section, we may, at our sole and absolute
        discretion, without notice to you, and without limiting any of our other
        rights or remedies at law or in equity, immediately suspend or terminate
        your user account.
      </p>
      <p>
        <br />
      </p>
      <p>6. User Contributions, Content Standards, and Copyright.&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <p>User Contributions.</p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <p>
            The Project allows for the use of interactive features
            (collectively, "Interactive Services") that allow users to post,
            submit, publish, display, or transmit to other users or other
            persons (hereinafter, "post") content or materials (collectively,
            "User Contributions") on or through the Site, Discord, and/or other
            means. All User Contributions must comply with the Content Standards
            set out in these Terms of Service.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={2}>
        <li>
          <p>
            Any User Contribution you post to the Site will be considered
            non-confidential and non-proprietary. By providing any User
            Contribution, you grant us and our affiliates and service providers,
            and each of their and our licensees, successors, and assigns the
            right to use, reproduce, modify, perform, display, distribute, and
            otherwise disclose to third parties any such material for any
            purpose.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={3}>
        <li>
          <p>You represent and warrant that:</p>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and our
            affiliates and service providers, and each of their and our
            respective licensees, successors, and assigns.
          </p>
        </li>
        <li>
          <p>
            All of your User Contributions do and will comply with these Terms
            of Service.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start={4}>
        <li>
          <p>
            You understand and acknowledge that you are responsible for any User
            Contributions you submit or contribute, and you, not Degen Moon
            Frens, have full responsibility for such content, including its
            legality, reliability, accuracy, and appropriateness.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={5}>
        <li>
          <p>
            We are not responsible or liable to any third party for the content
            or accuracy of any User Contributions posted by you or any other
            user of the Site, Discord, or Social Media Platforms.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={2}>
        <li>
          <p>Monitoring and Enforcement; Termination. We have the right to:</p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <p>
            Remove or refuse to post any User Contributions for any or no reason
            in our sole discretion.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={2}>
        <li>
          <p>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Contribution violates the Terms, including
            the Content Standards, infringes any intellectual property right or
            other right of any person or entity, threatens the personal safety
            of users of the Site or the public, or could create liability for
            Degen Moon Frens, LLC.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={3}>
        <li>
          <p>
            Disclose information about you to any third party who claims that
            material posted by you violates their rights, including their
            intellectual property rights or their right to privacy.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={4}>
        <li>
          <p>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the &nbsp;Site, Discord, or Social Media Platforms.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={5}>
        <li>
          <p>
            Terminate or suspend your access to all or part of the &nbsp;Site,
            Discord, or Social Media Platforms for any or no reason, including
            without limitation, any violation of these Terms.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        Without limiting the foregoing, we have the right to cooperate fully
        with any law enforcement authorities or court order requesting or
        directing us to disclose the identity or other information of anyone
        posting any materials on or through the Website. YOU WAIVE AND HOLD
        HARMLESS DEGEN MOON FRENS, LLC AND ITS AFFILIATES, LICENSEES, AND
        SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY
        OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p>&nbsp;</p>
      <p>
        Note, we cannot review material before it is posted on the &nbsp;Site,
        Discord, or Social Media Platforms, and cannot ensure prompt removal of
        objectionable material after it has been posted. Accordingly, we assume
        no liability for any action or inaction regarding transmissions,
        communications, or content provided by any user or third party. We have
        no liability or responsibility to anyone for performance or
        nonperformance of the activities described in this section.
      </p>
      <p>&nbsp;</p>
      <ol start={3}>
        <li>
          <p>
            Content Standards.&nbsp;These content standards apply to any and all
            User Contributions and use of Interactive Services. User
            Contributions must in their entirety comply with all applicable
            federal, state, local, and international laws and regulations.
            Without limiting the foregoing, User Contributions must not:
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <p>
            Contain any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory, or otherwise
            objectionable.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={2}>
        <li>
          <p>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={3}>
        <li>
          <p>
            Infringe any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={4}>
        <li>
          <p>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms and our
            Privacy Policy https://degenmoonfrens.xyz/privacy_policy.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={5}>
        <li>
          <p>Be likely to deceive any person.</p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={6}>
        <li>
          <p>
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={7}>
        <li>
          <p>
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, alarm, or annoy any other person.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={8}>
        <li>
          <p>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={9}>
        <li>
          <p>
            Involve commercial activities or sales, such as contests,
            sweepstakes, and other sales promotions, barter, or advertising.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={10}>
        <li>
          <p>
            Give the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start={4}>
        <li>
          <p>
            Copyright Infringement. If you believe that any User Contributions
            violate your copyright, please submit a notification
            to&nbsp;____________&nbsp;for instructions on sending us a notice of
            copyright infringement. It is the policy of Degen Moon Frens to
            terminate the user accounts of repeat infringers.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        7.&nbsp;&nbsp; &nbsp;&nbsp;Disclaimers and Limitation of
        Liability.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(a)&nbsp; &nbsp;&nbsp;YOU EXPRESSLY UNDERSTAND AND
        AGREE THAT YOUR ACCESS TO AND USE OF THE SITE, AND PARTICIPATION IN THE
        PROJECT IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED “AS IS” AND
        “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
        WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES
        REGARDING THE SITE, DISCORD AND/OR THE PROJECT AS A WHOLE, AND ANY PART
        OF EACH (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR
        ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
        CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY
        OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT
        REPRESENT OR WARRANT TO YOU THAT: (i) YOUR ACCESS TO OR USE OF THE SITE
        WILL MEET YOUR REQUIREMENTS, (ii) YOUR ACCESS TO OR USE OF THE SITE WILL
        BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (iii) USAGE DATA
        PROVIDED THROUGH THE SITE WILL BE ACCURATE, (iv) THE SITE OR ANY CONTENT
        OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE AND/OR THE DISCORD ARE
        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT
        YOU DISCLOSE WHEN YOU USE THE SITE AND/OR DISCORD WILL BE SECURE. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
        CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT
        APPLY TO YOU.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(b)&nbsp; &nbsp;&nbsp;YOU ACCEPT THE INHERENT
        SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE
        INTERNET AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY
        BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(c)&nbsp; &nbsp;&nbsp;WE WILL NOT BE RESPONSIBLE OR
        LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE
        ETHEREUM NETWORK NOR DO WE HAVE ANY CONTROL OVER, AND MAKE NO GUARANTEES
        REGARDING, ANY SMART CONTRACTS, INCLUDING, WITHOUT LIMITATION, THE
        FUNCTIONALITY OF OUR SMART CONTRACT.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(d)&nbsp; &nbsp;&nbsp;YOU UNDERSTAND AND AGREE THAT
        WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES WHICH
        YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
        INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED
        DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS
        OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY
        OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(e)&nbsp; &nbsp;&nbsp;YOU AGREE THAT OUR TOTAL,
        AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR
        RELATING TO THIS AGREEMENT OR YOUR ACCESS TO OR USE OF (OR YOUR
        INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN
        CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED
        TO THE GREATER OF (a) THE AMOUNTS YOU ACTUALLY PAID US UNDER THIS
        AGREEMENT IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR
        (b) $500.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(f)&nbsp; &nbsp;&nbsp;YOU ACKNOWLEDGE AND AGREE THAT
        WE HAVE MADE THE SITE AND DISCORD AVAILABLE TO YOU AND ENTERED INTO THIS
        AGREEMENT IN RELIANCE UPON YOUR REPRESENTATIONS AND WARRANTIES, THE
        WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE
        WOULD NOT BE ABLE TO PROVIDE THE SITE AND DISCORD TO YOU WITHOUT THESE
        LIMITATIONS. WE WILL NOT BE RESPONSIBLE FOR THE LOSS OF ANY
        CRYPTOCURRENCY OR NFTS IN THE EVENT OUR SITE, DISCORD, OR ANY MESSAGING
        OR SOCIAL MEDIA PLATFORM UTILIZED BY DEGEN MOON FRENS IS COMPROMISED.
        YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR TAKING DILIGENT STEPS TO
        INDEPENDENTLY VERIFY THE LEGITIMACY OF HYPERLINKS OR OTHER
        COMMUNICATIONS BEFORE CLICKING OR LINKING YOUR CRYPTOCURRENCY WALLET.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(g)&nbsp; &nbsp;&nbsp;WE WILL NOT BE RESPONSIBLE OR
        LIABLE TO YOU OR ANY OTHER PARTY FOR ERRORS OR FAILURES TO EXECUTE ANY
        PURCHASE OF YOUR DEGEN MOON FRENS NFT, INCLUDING, WITHOUT LIMITATION,
        ERRORS OR FAILURES CAUSED BY: (i) YOUR FAILURE TO FOLLOW OUR
        INSTRUCTIONS; (ii) ANY LOSS OF CONNECTION TO OUR SITE UNLESS CAUSED BY
        OUR GROSS NEGLIGENCE; (III) A FAILURE OF ANY SOFTWARE OR DEVICE USED BY
        YOU TO PURCHASE YOUR &nbsp;DEGEN MOON FRENS NFT; OR (IV) FOR ANY OTHER
        FAILURE TO EXECUTE YOUR &nbsp;DEGEN MOON FRENS NFT PURCHASE OR FOR
        ERRORS OR OMISSIONS IN CONNECTION WITH THIS ACTIVITY UNLESS CAUSED BY
        OUR GROSS NEGLIGENCE. DEGEN MOON FRENS, LLC MAKES NO REPRESENTATION THAT
        DEGEN MOON FRENS NFTS ARE LEGAL OR APPROPRIATE FOR USE OUTSIDE OF THE
        UNITED STATES OR THAT DEGEN MOON FRENS NFTS OR MAY BE EXPORTED FROM THE
        UNITED STATES OR FOR IMPORT INTO ANY FOREIGN COUNTRY. YOU ARE SOLELY
        RESPONSIBLE FOR COMPLYING WITH ALL IMPORT AND EXPORT LAWS AND
        REGULATIONS AND ALL APPLICABLE LAWS OF ANY JURISDICTION INSIDE AND
        OUTSIDE OF THE UNITED STATES FROM WHICH YOU MAY ACCESS THE SITE.
      </p>
      <p>
        <br />
      </p>
      <p>
        (f)&nbsp;&nbsp; &nbsp;&nbsp;THE SITE HAS BEEN COMPILED IN GOOD FAITH
        BY&nbsp;DEGEN MOON FRENS. HOWEVER, NO REPRESENTATION IS MADE AS TO THE
        COMPLETENESS OR ACCURACY OF THE INFORMATION IT CONTAINS. IN PARTICULAR,
        YOU SHOULD BE AWARE THAT THIS INFORMATION MAY BE INCOMPLETE, MAY CONTAIN
        ERRORS OR MAY HAVE BECOME OUT OF DATE.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>8.&nbsp;&nbsp; &nbsp;&nbsp;Assumption of Risk.</p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(a)&nbsp; &nbsp;&nbsp;Please be aware that prices of
        NFTs are extremely volatile and fluctuations in the prices of other NFTs
        and/or cryptocurrencies may impact the price of your NFT. &nbsp;NFTs
        have no extrinsic value and should NOT be considered an investment.
        &nbsp;Ethereum-based NFTs may become worthless or obsolete. &nbsp;We
        cannot guarantee that any purchasers of Degen Moon Frens NFTs will
        retain their original value, as their value is inherently subjective and
        factors occurring outside of the Site may materially impact the value
        and desirability of any particular NFT.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(b)&nbsp; &nbsp;&nbsp;By purchasing, holding or using
        a Degen Moon Frens NFT, you expressly acknowledge and assume all risks
        associated therewith including, but not limited to: (i) forgotten
        passwords; (ii) inability to access or use your digital wallet for any
        reason; (iii) mistyped addresses or improperly constructed instructions
        when transmitting or receiving Degen Moon Frens NFTs or any
        cryptocurrency; (iv) errors in the smart contract that mints Degen Moon
        Frens NFTs; (v) errors in the Degen Moon Frens NFTs; (vi) errors in our
        Site; (vii) inability to access or transfer a Degen Moon Frens NFT;
        (viii) inability to use, access, copy, or display any Content; (ix)
        blockchain malfunctions or other technical errors; (x) server failure or
        data loss; (xi) telecommunications failures; (xii) unfavorable
        regulatory determinations or actions (including with respect to NFTs or
        cryptocurrencies in general); (xiii) taxation of NFTs or
        cryptocurrencies; (xiv) uninsured losses; (xv) unanticipated risks;
        (xvi) volatility risks in the value of Degen Moon Frens NFTs and
        cryptocurrencies; (xvii) cybersecurity attacks; (xviii) weaknesses in
        Degen Moon Frens’ security; (xix) personal information disclosure; (xx)
        unauthorized access to applications; and/or (xxi) unauthorized
        third-party activities, including, without limitation, the introduction
        of viruses or other malicious code; and the use of phishing, sybil
        attacks, 51% attacks, bruteforcing, changes to the protocol rules of the
        Ethereum Blockchain (i.e., “forks”), or other means of attack that
        affect, in any way, the NFTs or the Content. &nbsp;If you have any
        questions regarding these risks, please contact us
        at&nbsp;_________________________________. &nbsp;&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(c)&nbsp; &nbsp;&nbsp;Any payments made to us via the
        Site will be effectuated through the Ethereum Blockchain. &nbsp;We have
        no control over these payments or transactions, nor do we have the
        ability to reverse any payments or transactions on the Ethereum
        Blockchain. &nbsp;You acknowledge that Degen Moon Frens has no liability
        to you or to any third party for any claims or damages that may arise as
        a result of your payment not reaching us, including, but not limited to,
        instances where your payment was not properly processed on the Ethereum
        Blockchain. &nbsp;It is solely your responsibility to confirm that your
        payment for a Degen Moon Frens NFT has been accepted. &nbsp;We do not
        provide refunds for any purchases that you might make on or through the
        Site. This no-refund policy shall apply at all times regardless of your
        decision to terminate usage of any Degen Moon Frens NFT purchased
        through our Site, any disruption to the operations of any components of
        the NFT, or any other reason whatsoever. &nbsp;By your continued use of
        our Site, you expressly release us, and any of our affiliates, from any
        and all liability or responsibility for any purchases made by you, or by
        someone acting on your behalf, with respect to your Degen Moon Frens
        NFT(s).
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(d)&nbsp; &nbsp;&nbsp;Nothing on this Site, and
        nothing pertaining to your participation in the Project, is intended to
        be an offering of securities in any jurisdiction nor does it constitute
        an offer to purchase shares, securities, or other financial products.
        &nbsp;It remains your sole responsibility to assure that the purchase of
        a Degen Moon Frens NFT is in compliance with laws and regulations in
        your jurisdiction.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(e)&nbsp; &nbsp;&nbsp;You assume all risks associated
        with using an Internet-based currency, including, but not limited to,
        the risk of hardware, software and Internet connections, the risk of
        malicious software introduction, and the risk that third parties may
        obtain unauthorized access to information stored within your wallet.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(f)&nbsp; &nbsp;&nbsp;NFTs, cryptocurrencies and
        blockchain technology are relatively new and the regulatory landscape is
        unsettled. &nbsp;New regulations could negatively impact such
        technologies impacting the value of your Degen Moon Frens NFT. &nbsp;You
        understand and accept all risk in that regard, and further understand
        that we bear no responsibility to notify you of any changes with respect
        to the regulatory landscape of NFTs, cryptocurrencies and/or blockchain
        technology.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(g)&nbsp; &nbsp;&nbsp;Degen Moon Frens is not
        responsible for any transaction between you and a third party
        (including, but not limited to, Third-Party Site or Secondary
        Marketplaces) and shall have no liability in connection with any such
        transaction.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(h)&nbsp; &nbsp;&nbsp;You assume all risk for any
        disruptions or other issues that may occur on the Ethereum Blockchain
        and/or impacts Ethereum or NFT functionality.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp; &nbsp;&nbsp;Degen Moon Frens cannot and
        does not represent or warrant that any Degen Moon Frens NFT, or its
        supporting systems or technology, is reliable, current, or error-free,
        meets your requirements, or that defects in the Degen Moon Frens NFT, or
        its supporting systems or technology, will be corrected.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(j)&nbsp; &nbsp;&nbsp;We are not responsible if your
        Degen Moon Frens NFT or content becomes inaccessible to you for any
        reason, or for any modifications or changes to your Degen Moon Frens NFT
        or any Content including any deletion, removal, or inaccessibility on
        our Site or otherwise. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(k)&nbsp; &nbsp;&nbsp;In addition to assuming all the
        above risks, you acknowledge that you have obtained sufficient
        information to make an informed decision to license the Degen Moon Frens
        NFT and that you understand and agree that you are solely responsible
        for determining the nature, potential value, suitability, and
        appropriateness of these risks for yourself.
      </p>
      <p>
        <br />
      </p>
      <p>9.&nbsp;&nbsp; &nbsp;&nbsp;Indemnification and Release.</p>
      <p>
        <br />
      </p>
      <ol start={10}>
        <li>
          <p>
            You agree to indemnify, defend and hold harmless Degen Moon Frens,
            LLC and its affiliates, licensors, suppliers and sponsors, and each
            of their respective directors, officers, members, shareholders,
            managers, agents, contractors, partners, advisors, employees,
            licensors, content creators, and suppliers (collectively, the “Degen
            Moon Frens Indemnified Parties”) from and against any claim,
            liability, loss, damage (actual and consequential) of any kind or
            nature, suit, judgment, litigation cost, and attorneys’ fees
            (collectively, “Liabilities”) arising out of or in any way related
            to (i) your breach of this Agreement, (ii) your misuse of the Site,
            Discord, or Social Media Platform (iii) your violation of any
            intellectual property right or other rights of another party, and/or
            (iv) your violation of Applicable Laws, rules or regulations in
            connection with your access to or use of the Site or Discord.
            &nbsp;This indemnity shall be applicable without regard to the
            negligence of any party, including any indemnified person, and shall
            survive any termination of this Agreement.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(b)&nbsp; &nbsp;&nbsp;To the maximum extent permitted
        by Applicable Law, you hereby irrevocably and unconditionally release
        and waive all claims against any Degen Moon Frens Indemnified Parties
        from any and all Liabilities of every kind and nature, arising out of or
        in any way connected with your use of the Site or purchase of a Degen
        Moon Frens NFT. &nbsp;To the extent that you do have any claims against
        Degen Moon Frens, LLC, you agree that: (i) any and all disputes, claims
        and causes of action against Degen Moon Frens, LLC arising out of or
        connected with your use of the Site and/or the Discord shall be resolved
        individually, without resort to any form of class action; and (ii) any
        and all claims, judgments and awards shall be limited to actual and
        documented damages and out-of-pocket costs incurred, but shall in no
        event include attorneys’ fees or litigation costs (e.g., court filing
        fees and related costs).
      </p>
      <p>
        <br />
      </p>
      <p>
        10.&nbsp; &nbsp;&nbsp;Termination. &nbsp;We reserve the right, in our
        sole and absolute discretion, to refuse, suspend, restrict or terminate
        your access to the Site, Social Media Platforms, and/or the Discord, or
        any portion thereof, without notice to you and for any reason or no
        reason. &nbsp;You acknowledge that we have the right, but not the
        obligation, exercisable in our sole and absolute discretion, to suspend
        or terminate your access to all or part of the Site and/or our Discord:
        (i) at the request of law enforcement or other government agencies; (ii)
        if the Site or Discord are discontinued or materially modified; (iii)
        upon the occurrence of any technical or security issues or problems;
        (iv) if you engage in any conduct that we believe, in our sole and
        absolute discretion, violates any provision of this Agreement or other
        incorporated agreements or guidelines or violates the rights of Degen
        Moon Frens or third parties; or (v) upon any breach by you of this
        Agreement. &nbsp;The exercise of our right to restrict or terminate your
        access to the Site and/or the Discord, whether or not ultimately
        determined to be justified, will not, under any circumstances,
        constitute our breach under this Agreement. &nbsp;Neither the exercise
        or nor the failure to exercise such right to restrict or terminate your
        access to the Site and/or our Discord will constitute an election of
        remedies or limit us in any manner in the enforcement of any other
        remedies available to us. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        11.&nbsp;&nbsp; &nbsp;&nbsp;Modifications. &nbsp;We reserve the right to
        make changes to the Terms, at any time and from time to time, at our
        sole and absolute discretion. &nbsp;If any changes are made to this
        Agreement, we will provide you with written notice of such changes by
        sending you an e-mail, providing a notice on our Site, or updating the
        date at top of this Agreement. &nbsp;Any changes made to this Agreement
        will go into effect on the date they are made, and your continued access
        to the Site, or use after the Terms have been updated with constitute
        your binding acceptance of these updates. If you do not agree to the
        revised Terms, you may not access or use our Site and/or our
        Discord.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        12.&nbsp;&nbsp; &nbsp;&nbsp;Severability.&nbsp;&nbsp;If any term or
        provision of this Agreement is deemed invalid, illegal, or unenforceable
        in any jurisdiction, such invalidity, illegality, or unenforceability
        shall not affect any other term or provision of this Agreement or
        invalidate or render unenforceable such term or provision in any other
        jurisdiction.
      </p>
      <p>
        <br />
      </p>
      <p>
        13.&nbsp;&nbsp; &nbsp;&nbsp;Governing Law.&nbsp;&nbsp;This Agreement and
        all matters related to it and/or any Degen Moon Frens NFT shall be
        governed by, construed, and enforced in accordance with the laws of the
        State of Wyoming, as they are applied to agreements entered and to be
        performed entirely within Wyoming and without regard to conflict of law
        principles, except to the extent that law is inconsistent with or
        preempted by federal law.
      </p>
      <p>
        <br />
      </p>
      <p>
        14.&nbsp;&nbsp; &nbsp;&nbsp;Dispute Resolution; Arbitration.&nbsp;
        Please read the arbitration agreement in this Section (“Arbitration
        Agreement”) carefully. It contains procedures for MANDATORY BINDING
        ARBITRATION AND A CLASS ACTION WAIVER.
      </p>
      <p>
        <br />
      </p>
      <p>
        Applicability of Arbitration Agreement. You agree that any dispute,
        claim, or request for relief relating in any way to your access or use
        of the Site, to any products sold or distributed through the Site, or to
        any aspect of your relationship with Degen Moon Frens, LLC, will be
        resolved by binding arbitration, rather than in court, except that Degen
        Moon Frens, LLC may seek equitable relief in court for infringement or
        other misuse of intellectual property rights (such as trademarks, trade
        dress, domain names, trade secrets, copyrights, and patents). This
        Arbitration Agreement shall apply, without limitation, to all disputes
        or claims and requests for relief that arose or were asserted before the
        effective date of this Agreement or any prior version of this Agreement.
        &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Arbitration Rules and Forum. The Federal Arbitration Act governs the
        interpretation and enforcement of this Arbitration Agreement. To begin
        an arbitration proceeding, you must send a letter requesting arbitration
        and describing your dispute or claim or request for relief to us at
        ________. The arbitration will be conducted by the American Arbitration
        Association (“AAA”), an established alternative dispute resolution
        provider. Any judgment on the award rendered by the arbitrator may be
        entered in any court of competent jurisdiction.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Authority of Arbitrator. The arbitrator shall have exclusive authority
        to (i) determine the scope and enforceability of this Arbitration
        Agreement and (ii) resolve any dispute related to the interpretation,
        applicability, enforceability or formation of this Arbitration Agreement
        including, but not limited to, any assertion that all or any part of
        this Arbitration Agreement is void or voidable. The arbitrator will
        decide the rights and liabilities, if any, of you and us. The
        arbitration proceeding will not be consolidated with any other matters
        or joined with any other cases or parties. The arbitrator shall have the
        authority to grant motions dispositive of all or part of any claim. The
        arbitrator shall have the authority to award monetary damages and to
        grant any non-monetary remedy or relief available to an individual under
        applicable law, the arbitral forum’s rules, and this Agreement
        (including the Arbitration Agreement). The arbitrator shall issue a
        written award and statement of decision describing the essential
        findings and conclusions on which the award is based, including the
        calculation of any damages awarded. The arbitrator has the same
        authority to award relief on an individual basis that a judge in a court
        of law would have. The award of the arbitrator is final and binding upon
        you and us. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Waiver of Jury Trial. YOU AND DEGEN MOON FRENS, LLC HEREBY WAIVE ANY
        CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN
        FRONT OF A JUDGE OR A JURY. You and we are instead electing that all
        disputes, claims, or requests for relief shall be resolved by
        arbitration under this Arbitration Agreement, except as set forth above.
        An arbitrator can award on an individual basis the same damages and
        relief as a court and must follow this Agreement as a court would.
        However, there is no judge or jury in arbitration, and court review of
        an arbitration award is subject to very limited review.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Waiver of Class or Other Non-Individualized Relief. ALL DISPUTES,
        CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION
        AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
        OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF
        MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH
        THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating
        that applicable law precludes enforcement of any of this section’s
        limitations as to a given dispute, claim, or request for relief, then
        such aspect must be severed from the arbitration and brought into the
        State or Federal Courts located in the State of Wyoming. All other
        disputes, claims, or requests for relief shall be arbitrated.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        30-Day Right to Opt Out. You have the right to opt out of the provisions
        of this Arbitration Agreement by sending written notice of your decision
        to opt out to DegenFrens@gmail.com within thirty (30) days after first becoming
        subject to this Arbitration Agreement. Your notice must include your
        name and address, your username (if any), and an unequivocal statement
        that you want to opt out of this Arbitration Agreement. If you opt out
        of this Arbitration Agreement, all other parts of this Agreement will
        continue to apply to you. Opting out of this Arbitration Agreement has
        no effect on any other arbitration agreements that you may currently
        have, or may enter in the future, with us.
      </p>
      <p>
        <br />
      </p>
      <p>
        Severability. Except as provided in above, if any part or parts of this
        Arbitration Agreement are found under the law to be invalid or
        unenforceable, then such specific part or parts shall be of no force and
        effect and shall be severed and the remainder of the Arbitration
        Agreement shall continue in full force and effect.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Survival of Agreement. This Arbitration Agreement will survive the
        termination of your relationship with us.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Modification. Notwithstanding any provision in this Agreement to the
        contrary, we agree that if we make any future material change to this
        Arbitration Agreement, you may reject that change within thirty (30)
        days of such change becoming effective by writing us at _____ and
        expressly opting out of this Arbitration Agreement.
      </p>
      <p>
        <br />
      </p>
      <p>
        15.&nbsp;&nbsp; &nbsp;&nbsp;Limitation on Time to File Claims.&nbsp;ANY
        CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THESE TERMS OF SERVICE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
        YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION
        OR CLAIM IS PERMANENTLY BARRED.
      </p>
      <p>&nbsp;</p>
      <p>
        16.&nbsp;&nbsp; &nbsp;&nbsp;Geographic Restrictions.&nbsp;The owner of
        the Site is based in the United States. We make no claims that the
        Website or any of its content is accessible or appropriate outside of
        the United States. Access to the Website may not be legal by certain
        persons or in certain countries. If you access the Website from outside
        the United States, you do so on your own initiative and are responsible
        for compliance with local laws.
      </p>
      <p>
        <br />
        17.&nbsp;&nbsp; &nbsp;&nbsp;Definitions.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            “Affiliate” means, with respect to any specified Person (as defined
            below), any other Person who, directly or indirectly, controls, is
            controlled by, or is under common control with such Person,
            including without limitation any general partner, managing member,
            officer or director of such Person or any venture capital fund now
            or hereafter existing that is controlled by one or more general
            partners or managing members of, or shares the same management
            company with, such Person.&nbsp;
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={2}>
        <li>
          <p>
            “Applicable Law” means any domestic or foreign law, rule, statute,
            regulation, by-law, order, protocol, code, decree, or other
            directive, requirement or guideline, published or in force which
            applies to, or is otherwise intended to govern or regulate, any
            Person, property, transaction, activity, event or other matter,
            including any rule, order, judgment, directive or other requirement
            or guideline issued by any Governmental Authority (as defined below)
            having jurisdiction over the Degen Moon Frens NFT Project, you, or
            as otherwise duly enacted, enforceable by law, the common law or
            equity.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={3}>
        <li>
          <p>
            “Discord” means our Discord server located at
            https://discord.gg/degenmoonfrens. &nbsp;
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={4}>
        <li>
          <p>
            “ETH” means the Ethereum Blockchain utility token that may be used
            to purchase computational resources to run decentralized
            applications or perform actions on the Ethereum Blockchain.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={5}>
        <li>
          <p>
            “Ethereum Address” means the unique public key digital asset
            identifier that points to an Ethereum-compatible wallet to which ETH
            may be sent or stored.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={6}>
        <li>
          <p>
            “Ethereum Blockchain” means the underlying blockchain
            infrastructure.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={7}>
        <li>
          <p>
            “Governmental Authority” includes any domestic or foreign federal,
            provincial or state, municipal, local or other governmental,
            regulatory, judicial or administrative authority.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <ol start={8}>
        <li>
          <p>“NFT” means any blockchain-tracked, non-fungible token.</p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(i)&nbsp;&nbsp; &nbsp;&nbsp;“Person” means an
        individual, a partnership, a joint venture, a limited liability company
        or partnership, a corporation, a trust, an unincorporated organization
        or a government or any department or any agency or political subdivision
        thereof.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(j)&nbsp; &nbsp;&nbsp;“Privacy Policy” means our
        privacy policy located at&nbsp;______________.
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(k)&nbsp; &nbsp;&nbsp;“Project” refers to, in the
        aggregate, the Degen Moon Frens NFTs, our Site (as defined below),
        Discord server, and Social Media Platforms, and any features, functions,
        services, products, rewards, offers, content, materials or information
        available on or through our Site or other means.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(l)&nbsp; &nbsp;&nbsp;“Site” means the website
        located at https://degenmoonfrens.xyz, as well as our Discord, and
        Social Media Platforms.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;(m)&nbsp;&nbsp; &nbsp;&nbsp;“Social Media
        Platform(s)” means any platform that we use that allows us or you to
        create and share content and/or communicate with other users and
        holders.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </>
  );
};

export default TOS;
