import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { BrowserRouter as Router } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import Modal from "react-modal";

import { UserContextProvider } from "./providers/UserContextProvider";
import { DrawerProvider } from "./providers/DrawerContext";
import App from "./App";

// import "./styles/app.scss";
import "./styles/index.css";

const firebaseConfig = {
  apiKey: "AIzaSyAigCiGxVKbWe2v9TVQzaEMBwxYqLce-RI",
  authDomain: "degenfrensinvite.firebaseapp.com",
  projectId: "degenfrensinvite",
  storageBucket: "degenfrensinvite.appspot.com",
  databaseURL: "https://degenfrensinvite-default-rtdb.firebaseio.com",
  messagingSenderId: "561140116435",
  appId: "1:561140116435:web:60c145ee395590d01a1d26",
  measurementId: "G-3ER5DCLXME",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

ReactDOM.render(
  <Router>
    <UserContextProvider>
      <DrawerProvider>
        <App />
      </DrawerProvider>
    </UserContextProvider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
